// Iterating arrays, objects, & strings...

export function isEmpty(object: any) {
	return Object.keys(object).length === 0;
}

// The objects to be compared need properties entered in the same order
export function isEqualWithSameOrder(firstObject: any, secondObject: any) {
	const firstStringValue = JSON.stringify(firstObject);
	const secondStringValue = JSON.stringify(secondObject);
	return firstStringValue !== secondStringValue;
}

export function deepCopy(data: any, objMap?: WeakMap<any, any>) {
	if (!objMap) {
		// Map for handle recursive objects
		objMap = new WeakMap();
	}
	// recursion wrapper
	const deeper = (value: any): any => {
		if (value && typeof value === 'object') {
			return deepCopy(value, objMap);
		}
		return value;
	};
	// Array value
	if (Array.isArray(data)) {
		return data.map(deeper);
	}
	// Object value
	if (data && typeof data === 'object') {
		// Same object seen earlier
		if (objMap.has(data)) {
			return objMap.get(data);
		}
		// Date object
		if (data instanceof Date) {
			const result = new Date(data.valueOf());
			objMap.set(data, result);
			return result;
		}
		// Use original prototype
		const node = Object.create(Object.getPrototypeOf(data));
		// Save object to map before recursion
		objMap.set(data, node);
		for (const [key, value] of Object.entries(data)) {
			node[key] = deeper(value);
		}
		return node;
	}
	// Scalar value
	return data;
}

export function sortObj(object: any) {
	object.sort((firstElement: any, secondElement: any) => (firstElement.order < secondElement.order ? 1 : -1));
	return object;
}

export function compareObjects(obj1: any, obj2: any) {
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);
	if (keys1.length !== keys2.length) {
		return false;
	}
	for (const key of keys1) {
		if (obj1[key] !== obj2[key]) {
			return false;
		}
	}
	return true;
}

export function compareObjectArrays(arr1: any, arr2: any) {
	if (arr1.length !== arr2.length) {
		return false;
	}
	for (let i = 0; i < arr1.length; i++) {
		if (!compareObjects(arr1[i], arr2[i])) {
			return false;
		}
	}
	return true;
}
