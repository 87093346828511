import { IOPSWATProduct } from '../models';

export enum SUPPORT_LEVEL {
	LEVEL_BASIC = -1, // Inventory list, health
	LEVEL_0 = 0, // Dashboard, report
	LEVEL_1 = 1, // vpack
	LEVEL_2 = 2, //Engine update
	LEVEL_3 = 3, // Volumn
	LEVEL_4 = 4, // Remote command
}

export enum LICENSE_TYPE {
	NONE = 0, // free product
	LICENSING = 1, // With expiration
	PERPETUAL = 100, // Unlimited,
	BOTH = 101, // With expiration and Unlimited
}

const netwallDoc = 'https://docs.opswat.com/netwall';
const netwallDiodeDoc = 'https://docs.opswat.com/netwalldiode';
const netwallIntro = '<strong>NetWall</strong> provides unilateral or bilateral inter-zone data transfer and streaming across the zones.';
export const OPSWAT_PRODUCTS: Record<string, IOPSWATProduct> = {
	MK5: {
		KEY: 'mk5',
		TYPE: 'MK5',
		SHORT_NAME: 'Kiosk L-Series',
		FULL_NAME: 'MetaDefender Kiosk L-Series',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_3,
		LICENSING: LICENSE_TYPE.LICENSING,
		DOC: 'https://docs.opswat.com/mdkiosk-linux',
		INTRO: '<Strong>Trust at the Point of Entry</strong></br> OPSWAT MetaDefender Kiosk is your digital security guard. Available in multiple form factors to meet your portable media security needs and support compliance requirements, the Kiosk series scans more than 20 types of removable media and supports a wide array of file systems to keep your...',
	},
	KIOSK: {
		KEY: 'kiosk',
		TYPE: 'KIOSK',
		SHORT_NAME: 'Kiosk K-Series',
		FULL_NAME: 'MetaDefender Kiosk K-Series',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_2,
		DOC: 'https://docs.opswat.com/mdkiosk',
		LICENSING: LICENSE_TYPE.LICENSING,
		INTRO: '<Strong>Trust at the Point of Entry</strong></br> OPSWAT MetaDefender Kiosk is your digital security guard. Available in multiple form factors to meet your portable media security needs and support compliance requirements, the Kiosk series scans more than 20 types of removable media and supports a wide array of file systems to keep your...',
	},
	VAULT: {
		KEY: 'vault',
		TYPE: 'VAULT',
		SHORT_NAME: 'MFT',
		FULL_NAME: 'MetaDefender Managed File Transfer',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_1,
		LICENSING: LICENSE_TYPE.LICENSING,
		DOC: 'https://docs.opswat.com/mdvault',
		INTRO: '<Strong>Trust at the Point of Entry</strong></br> OPSWAT MetaDefender Kiosk is your digital security guard. Available in multiple form factors to meet your portable media security needs and support compliance requirements, the Kiosk series scans more than 20 types of removable media and supports a wide array of file systems to keep your...',
	},
	MDD: {
		KEY: 'mdd',
		TYPE: 'MDD',
		SHORT_NAME: 'Drive',
		FULL_NAME: 'MetaDefender Drive',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.LICENSING,
		DOC: 'https://docs.opswat.com/mddrive',
		INTRO: '<strong>Trust you can hold in your hand</strong></br> MetaDefender Drive creates a portable perimeter, anywhere maintaining an airgap is critical.',
	},
	MDCORE: {
		KEY: 'mdcore',
		TYPE: 'MDCORE',
		SHORT_NAME: 'Core',
		FULL_NAME: 'MetaDefender Core',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_2,
		LICENSING: LICENSE_TYPE.LICENSING,
		DOC: 'https://docs.opswat.com/mdcore',
		INTRO: '<strong>Advanced Threat Prevention Solution</strong></br> MetaDefender Core enables you to integrate advanced malware prevention and detection capabilities into your existing IT solutions and infrastructure for better handling common attack vectors: securing web portals from malicious file upload attacks, augmenting cybersecurity products...',
	},
	UF3: {
		KEY: 'uf3',
		TYPE: 'UF3',
		SHORT_NAME: 'Media Firewall',
		FULL_NAME: 'MetaDefender Media Firewall',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_0,
		LICENSING: LICENSE_TYPE.NONE,
		DOC: 'https://docs.opswat.com/mdmediafirewall',
		INTRO: '<strong>Providing Another Option for Securing and Controlling Portable Media Use in Critical Infrastructure</strong></br>',
	},
	MDICAP: {
		KEY: 'mdicap',
		TYPE: 'MDICAP',
		SHORT_NAME: 'ICAP',
		FULL_NAME: 'MetaDefender ICAP Server',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_1,
		LICENSING: LICENSE_TYPE.LICENSING,
		DOC: 'https://docs.opswat.com/mdicap',
		INTRO: '<strong>Advanced Threat Prevention for Network Traffic</strong></br> MetaDefender ICAP Server is a plug-and-play solution to protect your network against malicious internet content.',
	},
	FSCAN: {
		KEY: 'fscan',
		TYPE: 'FSCAN',
		SHORT_NAME: 'Sandbox',
		FULL_NAME: 'MetaDefender Sandbox',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.LICENSING,
		DOC: 'https://docs.opswat.com/filescan',
		INTRO: '<strong>Adaptive Threat Analysis</strong></br> MetaDefender Sandbox’s unique adaptive threat analysis technology enables zero-day malware detection and extracts more indicators of comprise.',
	},
	NEURALYZER: {
		KEY: 'neuralyzer',
		TYPE: 'NEURALYZER',
		SHORT_NAME: 'MD OT Security',
		FULL_NAME: 'MetaDefender OT Security',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.BOTH,
		DOC: 'https://docs.opswat.com/md-ot-security',
		INTRO: '<strong>OT Asset Visibility Simplified</strong></br> MetaDefender OT Security gives you full visibility into your organization’s OT assets and networks by continuously discovering assets, monitoring for threats, vulnerabilities, supply chain violations, and non-compliance issues.',
	},
	OTFUSE: {
		KEY: 'otfuse',
		TYPE: 'OTFUSE',
		SHORT_NAME: 'Firewall & IPS',
		FULL_NAME: 'MetaDefender Industrial Firewall & IPS',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.PERPETUAL,
		DOC: 'https://docs.opswat.com/mdotfuse',
		INTRO: '<strong>Industrial Firewall for OT/ICS Assets</strong></br> Industrial Firewall & IPS is an industrial security appliance and intelligent Intrusion Prevention System (IPS) that sits in front of industrial endpoints to protect mission-critical PLC, VFD, DCS, and other network connected devices.',
	},
	MDSS: {
		KEY: 'mdss',
		TYPE: 'MDSS',
		SHORT_NAME: 'Storage Security',
		FULL_NAME: 'MetaDefender Storage Security',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_1,
		LICENSING: LICENSE_TYPE.LICENSING,
		DOC: 'https://docs.opswat.com/mdss',
		INTRO: '<strong>Secure Your Enterprise Data Storage</strong></br> Dedicated cloud storage solutions facilitate access, sharing and collaboration between the users of an organization, but can contain hidden malware and file-borne threats. MetaDefender Storage Security offers visibility into your stored data by identifying threats and reporting potential data loss...',
	},
	MDEMAIL: {
		KEY: 'mdemail',
		TYPE: 'MDEMAIL',
		SHORT_NAME: 'Email',
		FULL_NAME: 'MetaDefender Email Gateway Security',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.LICENSING,
		DOC: 'https://docs.opswat.com/mdemail',
		INTRO: '<strong>A New Approach to Email Protection</strong></br> Organizations are seeking to cover all aspects of critical infrastructure protection, including email security. The challenges are compounded by the growing pressure of state-sponsored attacks and the increasing difficulty of detecting unknown threats.',
	},

	// Unidirectional
	NWUSGBLUE: {
		KEY: 'nwusgblue',
		TYPE: 'NWUSGBLUE',
		SHORT_NAME: 'MetaDefender USG Blue',
		FULL_NAME: 'MetaDefender Unidirectional Security Gateway Blue',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.BOTH,
		DOC: netwallDoc,
		INTRO: netwallIntro,
	},
	NWUSGRED: {
		KEY: 'nwusgred',
		TYPE: 'NWUSGRED',
		SHORT_NAME: 'MetaDefender USG Red',
		FULL_NAME: 'MetaDefender Unidirectional Security Gateway Red',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.NONE,
		DOC: netwallDoc,
		INTRO: netwallIntro,
	},

	// Bilateral
	NWBSGBLUE: {
		KEY: 'nwbsgblue',
		TYPE: 'NWBSGBLUE',
		SHORT_NAME: 'MetaDefender BSG Blue',
		FULL_NAME: 'MetaDefender Bilateral Security Gateway Blue',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.BOTH,
		DOC: netwallDoc,
		INTRO: netwallIntro,
	},
	NWBSGRED: {
		KEY: 'nwbsgred',
		TYPE: 'NWBSGRED',
		SHORT_NAME: 'MetaDefender BSG Red',
		FULL_NAME: 'MetaDefender Bilateral Security Gateway Red',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.NONE,
		DOC: netwallDoc,
		INTRO: netwallIntro,
	},

	// Threat Protection
	NWTPBLUE: {
		KEY: 'nwtpblue',
		TYPE: 'NWTPBLUE',
		SHORT_NAME: 'MetaDefender TP Blue',
		FULL_NAME: 'MetaDefender Threat Protection Blue',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.BOTH,
		DOC: netwallDoc,
		INTRO: netwallIntro,
	},
	NWTPRED: {
		KEY: 'nwtpred',
		TYPE: 'NWTPRED',
		SHORT_NAME: 'MetaDefender TP Red',
		FULL_NAME: 'MetaDefender Threat Protection Red',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.NONE,
		DOC: netwallDoc,
		INTRO: netwallIntro,
	},

	// Optical Diode
	NWODBLUE: {
		KEY: 'nwodblue',
		TYPE: 'NWODBLUE',
		SHORT_NAME: 'MetaDefender OD-100 Blue',
		FULL_NAME: 'MetaDefender Optical Diode Blue',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.BOTH,
		DOC: netwallDiodeDoc,
		INTRO: netwallIntro,
	},
	NWODRED: {
		KEY: 'nwodred',
		TYPE: 'NWODRED',
		SHORT_NAME: 'MetaDefender OD-100 Red',
		FULL_NAME: 'MetaDefender Optical Diode Red',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.NONE,
		DOC: netwallDiodeDoc,
		INTRO: netwallIntro,
	},
	MDSSC: {
		KEY: 'mdssc',
		TYPE: 'MDSSC',
		SHORT_NAME: 'Software Supply Chain',
		FULL_NAME: 'MetaDefender Software Supply Chain',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.LICENSING,
		DOC: 'https://docs.opswat.com/supply-chain',
		INTRO: netwallIntro,
	},
	// Transfer Guard
	NWTGBLUE: {
		KEY: 'nwtgblue',
		TYPE: 'NWTGBLUE',
		SHORT_NAME: 'MetaDefender TG Blue',
		FULL_NAME: 'MetaDefender Transfer Guard Blue',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.LICENSING,
		DOC: netwallDoc,
		INTRO: netwallIntro,
	},
	NWTGRED: {
		KEY: 'nwtgred',
		TYPE: 'NWTGRED',
		SHORT_NAME: 'MetaDefender TG Red',
		FULL_NAME: 'MetaDefender Transfer Guard Red',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.NONE,
		DOC: netwallDoc,
		INTRO: netwallIntro,
	},
	MDIF: {
		KEY: 'mdif',
		TYPE: 'MDIF',
		SHORT_NAME: 'Industrial Firewall',
		FULL_NAME: 'MetaDefender Industrial Firewall',
		SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
		LICENSING: LICENSE_TYPE.LICENSING,
		DOC: 'https://www.opswat.com/docs/mdif4p',
		INTRO: '<strong>The MetaDefender Industrial Firewall 4 Ports (MD-IF-4P)</strong></br> is a ruggedized industrial firewall designed to meet the stringent demands of mission-critical infrastructure. It features transparent Layer 2 operation, static routing, and stateful packet inspection across four high-speed Ethernet ports. Enhanced by Firewall Learning Mode (FLM) powered by Machine Learning (ML), it monitors and analyzes network traffic, automatically generating security policies. These policies can be easily implemented as protocol-specific Deep Packet Inspection (DPI) rule sets to block anomalies, zero-day vulnerabilities, and DoS/DDoS attacks. ',
	},
};

export const KIOSK_FAMILY = {
	KEY: 'mdkiosk',
	TYPE: 'MDKIOSK',
	SHORT_NAME: 'Kiosk',
	FULL_NAME: 'MetaDefender Kiosk',
	SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_1,
};

export const NETWALL_FAMILY = {
	KEY: 'netwall',
	TYPE: 'NETWALL',
	SHORT_NAME: 'MetaDefender NetWall',
	FULL_NAME: 'MetaDefender NetWall',
	SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_1,
};

export const INDUSTRIAL_FIREWALL_FAMILY = {
	KEY: 'industrialFirewall',
	TYPE: 'INDUSTRIAL_FIREWALL',
	SHORT_NAME: 'MetaDefender Industrial Firewall',
	FULL_NAME: 'MetaDefender Industrial Firewall',
	SUPPORT_LEVEL: SUPPORT_LEVEL.LEVEL_BASIC,
};
export const KEY_OF_PRODUCT_NAME = 'SHORT_NAME'; // SHORT_NAME or FULL_NAME
