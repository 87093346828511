import { CUSTOM_USER_ROLE_ID } from '@app/views/content-view/modules/user-management/constants';
import { OPSWAT_PRODUCTS } from './product.constant';
import {
	PROCESSING_HISTORY_ACTION,
	PROCESSING_HISTORY_RESULT,
	PROCESSING_HISTORY_STATUS,
	PROCESSING_HISTORY_WORKFLOW,
} from '@app/views/content-view/modules/inventory/constants/processing-history.constant';

export const LICENSE = {
	NO_LICENSE: 'No License',
	EXPIRED: 'Expired',
	EXPIRING_IN_30_DAYS: 'Expiring in 30 days',
	EXPIRING_IN_90_DAYS: 'Expiring in 31-90 days',
	EXPIRING_IN_90PLUS_DAYS: 'Expiring in 90+ days',
	PERPETUAL: 'Perpetual',
};

const FILTER_TYPE = {
	LICENSE: 'License',
	CONNECTION_STATUS: 'Connection Status',
	CONFIGURATION_TYPE: 'Configuration Type',
	CONFIGURATION_STATUS: 'Configuration Status',
};

const CONNECTION_STATUS = {
	CONNECTED: {
		id: 'connected',
		name: 'Connected',
	},
	DISCONNECTED: {
		id: 'disconnected',
		name: 'Disconnected',
	},
	NO_LICENSE: {
		id: 'no license',
		name: 'No License',
	},
	PENDING: {
		id: 'pending',
		name: 'Pending',
	},
};

export const DEFAULT_TIME_RANGE_VALUE = 7;

export const FILTER_STATUS_CHILDREN = [
	{ id: CONNECTION_STATUS.CONNECTED.id, name: CONNECTION_STATUS.CONNECTED.name },
	{ id: CONNECTION_STATUS.DISCONNECTED.id, name: CONNECTION_STATUS.DISCONNECTED.name },
	{ id: CONNECTION_STATUS.NO_LICENSE.id, name: CONNECTION_STATUS.NO_LICENSE.name },
	{ id: CONNECTION_STATUS.PENDING.id, name: CONNECTION_STATUS.PENDING.name },
];

export const DEFAULT_DYNAMIC_FILTERS = {
	actions: [
		PROCESSING_HISTORY_ACTION.sanitized.name,
		PROCESSING_HISTORY_ACTION.sanitized_partially.name,
		PROCESSING_HISTORY_ACTION.sanitized_failed.name,
		PROCESSING_HISTORY_ACTION.sanitized_skipped.name,
		PROCESSING_HISTORY_ACTION.sanitized_timeout.name,
	],
	results: [
		// Temporary fix will be later get from BE
		PROCESSING_HISTORY_RESULT.whitelisted.name,
		PROCESSING_HISTORY_RESULT.allowed_verdict_by_coo.name,
		PROCESSING_HISTORY_RESULT.blacklisted.name,
		PROCESSING_HISTORY_RESULT.blocked_by_post_action.name,
		PROCESSING_HISTORY_RESULT.blocked_verdict_by_deep_cdr.name,
		PROCESSING_HISTORY_RESULT.blocked_verdict_by_coo.name,

		PROCESSING_HISTORY_RESULT.blocked_verdict_by_sandbox.name,

		PROCESSING_HISTORY_RESULT.blocked_verdict_by_sbom.name,
		PROCESSING_HISTORY_RESULT.cancelled.name,
		PROCESSING_HISTORY_RESULT.coo_failed.name,
		PROCESSING_HISTORY_RESULT.coo_timed_out.name,
		PROCESSING_HISTORY_RESULT.encryption_failed.name,
		PROCESSING_HISTORY_RESULT.encryption_timedout.name,
		PROCESSING_HISTORY_RESULT.encrypted_archive.name,
		PROCESSING_HISTORY_RESULT.exceeded_archive_depth.name,
		PROCESSING_HISTORY_RESULT.exceeded_archive_file_number.name,

		PROCESSING_HISTORY_RESULT.exceeded_archive_size.name,
		PROCESSING_HISTORY_RESULT.exceeded_archive_timeout.name,

		PROCESSING_HISTORY_RESULT.extraction_failed.name,
		PROCESSING_HISTORY_RESULT.failed.name,
		PROCESSING_HISTORY_RESULT.has_vulnerability.name,
		PROCESSING_HISTORY_RESULT.in_progress.name,
		PROCESSING_HISTORY_RESULT.infected.name,
		PROCESSING_HISTORY_RESULT.global_timeout_exceeded.name,
		PROCESSING_HISTORY_RESULT.known_bad.name,
		PROCESSING_HISTORY_RESULT.known_good.name,
		PROCESSING_HISTORY_RESULT.likely_malicious_verdict_by_sandbox.name,

		PROCESSING_HISTORY_RESULT.malicious_verdict_by_sandbox.name,

		PROCESSING_HISTORY_RESULT.mismatch.name,
		PROCESSING_HISTORY_RESULT.no_threat_detected.name,
		PROCESSING_HISTORY_RESULT.not_scanned.name,
		PROCESSING_HISTORY_RESULT.non_vulnerable_verdict_by_sbom.name,

		PROCESSING_HISTORY_RESULT.password_protected_document.name,

		PROCESSING_HISTORY_RESULT.potentially_vulnerable_file.name,

		PROCESSING_HISTORY_RESULT.potentially_unwanted.name,
		PROCESSING_HISTORY_RESULT.prevent_data_leak_failed.name,
		PROCESSING_HISTORY_RESULT.prevent_data_leak_timed_out.name,

		PROCESSING_HISTORY_RESULT.reputation_failed.name,
		PROCESSING_HISTORY_RESULT.reputation_timed_out.name,
		PROCESSING_HISTORY_RESULT.sanitization_failed.name,
		PROCESSING_HISTORY_RESULT.sanitization_timedout.name,
		PROCESSING_HISTORY_RESULT.sanitized.name,
		PROCESSING_HISTORY_RESULT.sandbox_failed.name,
		PROCESSING_HISTORY_RESULT.sandbox_timeout.name,
		PROCESSING_HISTORY_RESULT.sbom_failed.name,
		PROCESSING_HISTORY_RESULT.sbom_timed_out.name,
		PROCESSING_HISTORY_RESULT.sensitive_data_found.name,
		PROCESSING_HISTORY_RESULT.suspicious.name,

		PROCESSING_HISTORY_RESULT.suspicious_verdict_by_sandbox.name,

		PROCESSING_HISTORY_RESULT.unknown_verdict_by_coo.name,
		PROCESSING_HISTORY_RESULT.unsupported_file_type.name,
		PROCESSING_HISTORY_RESULT.vulnerable_verdict_by_sbom.name,

		PROCESSING_HISTORY_RESULT.weak_vulnerable_verdict_by_sbom.name,
		PROCESSING_HISTORY_RESULT.yara_rule_matched.name,
	],
	workflows: [
		PROCESSING_HISTORY_WORKFLOW.file_process.name,
		PROCESSING_HISTORY_WORKFLOW.file_process_wo_archive.name,
		PROCESSING_HISTORY_WORKFLOW.kiosk.name,
		PROCESSING_HISTORY_WORKFLOW.metadefender_vault.name,
		PROCESSING_HISTORY_WORKFLOW.email_security.name,
		PROCESSING_HISTORY_WORKFLOW.email_security_wo_cdr.name,
		PROCESSING_HISTORY_WORKFLOW.secure_storage.name,
	],
};
export const FILTER = {
	EVENT_AND_HISTORY: [
		{
			id: 'action',
			name: 'Action',
			children: [
				{ id: 'ADD', name: 'Add' },
				{ id: 'UPDATE', name: 'Update' },
				{ id: 'DELETE', name: 'Delete' },
				{ id: 'APPLY', name: 'Apply' },
				{ id: 'SIGN_IN', name: 'Sign In' },
				{ id: 'SIGN_OUT', name: 'Sign Out' },
				{ id: 'DOWNLOAD', name: 'Download' },
			],
		},
		{
			id: 'impactedType',
			name: 'Impacted',
			children: [
				{ id: 'ACCOUNT', name: 'Account' },
				{ id: 'USERS_AND_GROUPS', name: 'Users & Groups' },
				{ id: 'METADEFENDER_KIOSK_L1001', name: OPSWAT_PRODUCTS.MK5.SHORT_NAME },
				{ id: 'METADEFENDER_KIOSK_K3001', name: OPSWAT_PRODUCTS.KIOSK.SHORT_NAME },
				{ id: OPSWAT_PRODUCTS.MDCORE.TYPE, name: OPSWAT_PRODUCTS.MDCORE.SHORT_NAME },
				{ id: OPSWAT_PRODUCTS.MDICAP.TYPE, name: OPSWAT_PRODUCTS.MDICAP.SHORT_NAME },
				{ id: OPSWAT_PRODUCTS.UF3.TYPE, name: OPSWAT_PRODUCTS.UF3.SHORT_NAME },
				{ id: OPSWAT_PRODUCTS.VAULT.TYPE, name: OPSWAT_PRODUCTS.VAULT.SHORT_NAME },
				{ id: OPSWAT_PRODUCTS.OTFUSE.TYPE, name: OPSWAT_PRODUCTS.OTFUSE.SHORT_NAME },
				// { id: OPSWAT_PRODUCTS.OWC.TYPE, name: OPSWAT_PRODUCTS.OWC.SHORT_NAME },
				// { id: OPSWAT_PRODUCTS.MA.TYPE, name: OPSWAT_PRODUCTS.MA.SHORT_NAME },
				{ id: OPSWAT_PRODUCTS.NEURALYZER.TYPE, name: OPSWAT_PRODUCTS.NEURALYZER.SHORT_NAME },
				{ id: OPSWAT_PRODUCTS.FSCAN.TYPE, name: OPSWAT_PRODUCTS.FSCAN.SHORT_NAME },
				{ id: OPSWAT_PRODUCTS.MDD.TYPE, name: OPSWAT_PRODUCTS.MDD.SHORT_NAME },
				{ id: OPSWAT_PRODUCTS.MDSS.TYPE, name: OPSWAT_PRODUCTS.MDSS.SHORT_NAME },
				{ id: OPSWAT_PRODUCTS.MDEMAIL.TYPE, name: OPSWAT_PRODUCTS.MDEMAIL.SHORT_NAME },

				// Unidirectional
				{ id: OPSWAT_PRODUCTS.NWUSGBLUE.TYPE, name: OPSWAT_PRODUCTS.NWUSGBLUE.SHORT_NAME },
				{ id: OPSWAT_PRODUCTS.NWUSGRED.TYPE, name: OPSWAT_PRODUCTS.NWUSGRED.SHORT_NAME },

				// Bilateral
				{ id: OPSWAT_PRODUCTS.NWBSGBLUE.TYPE, name: OPSWAT_PRODUCTS.NWBSGBLUE.SHORT_NAME },
				{ id: OPSWAT_PRODUCTS.NWBSGRED.TYPE, name: OPSWAT_PRODUCTS.NWBSGRED.SHORT_NAME },

				// Threat Protection
				{ id: OPSWAT_PRODUCTS.NWTPBLUE.TYPE, name: OPSWAT_PRODUCTS.NWTPBLUE.SHORT_NAME },
				{ id: OPSWAT_PRODUCTS.NWTPRED.TYPE, name: OPSWAT_PRODUCTS.NWTPRED.SHORT_NAME },

				// Optical Diode
				{ id: OPSWAT_PRODUCTS.NWODBLUE.TYPE, name: OPSWAT_PRODUCTS.NWODBLUE.SHORT_NAME },
				{ id: OPSWAT_PRODUCTS.NWODRED.TYPE, name: OPSWAT_PRODUCTS.NWODRED.SHORT_NAME },

				// Supply Chain
				{ id: OPSWAT_PRODUCTS.MDSSC.TYPE, name: OPSWAT_PRODUCTS.MDSSC.SHORT_NAME },
				// Transfer Guard
				{ id: OPSWAT_PRODUCTS.NWTGBLUE.TYPE, name: OPSWAT_PRODUCTS.NWTGBLUE.SHORT_NAME },
				{ id: OPSWAT_PRODUCTS.NWTGRED.TYPE, name: OPSWAT_PRODUCTS.NWTGRED.SHORT_NAME },
				// Industrial Firewall
				{ id: OPSWAT_PRODUCTS.MDIF.TYPE, name: OPSWAT_PRODUCTS.MDIF.SHORT_NAME },
			],
		},
	],

	KIOSK_INSTANCE: [
		{
			id: 'license',
			name: FILTER_TYPE.LICENSE,
			children: [
				{ id: '-1', name: LICENSE.NO_LICENSE },
				{ id: '-2', name: LICENSE.EXPIRED },
				{ id: '30', name: LICENSE.EXPIRING_IN_30_DAYS },
				{ id: '90', name: LICENSE.EXPIRING_IN_90_DAYS },
				{ id: '91', name: LICENSE.EXPIRING_IN_90PLUS_DAYS },
			],
		},
		{
			id: 'status',
			name: FILTER_TYPE.CONNECTION_STATUS,
			children: FILTER_STATUS_CHILDREN,
		},
		{
			id: 'settingsType',
			name: FILTER_TYPE.CONFIGURATION_TYPE,
			children: [
				{ id: 'CUSTOM', name: 'Custom' },
				{ id: 'BY_GROUP', name: 'By Group' },
			],
		},
		{
			id: 'settingsStatus',
			name: FILTER_TYPE.CONFIGURATION_STATUS,
			children: [
				{ id: 'OUT_OF_DATE', name: 'Out of Date' },
				{ id: 'PENDING', name: 'Pending' },
				{ id: 'APPLIED', name: 'Applied' },
			],
		},
	],

	CORE_INSTANCE: [
		{
			id: 'license',
			name: FILTER_TYPE.LICENSE,
			children: [
				{ id: '-1', name: LICENSE.NO_LICENSE },
				{ id: '-2', name: LICENSE.EXPIRED },
				{ id: '30', name: LICENSE.EXPIRING_IN_30_DAYS },
				{ id: '90', name: LICENSE.EXPIRING_IN_90_DAYS },
				{ id: '91', name: LICENSE.EXPIRING_IN_90PLUS_DAYS },
			],
		},
		{
			id: 'status',
			name: FILTER_TYPE.CONNECTION_STATUS,
			children: FILTER_STATUS_CHILDREN,
		},
		{
			id: 'settingsType',
			name: FILTER_TYPE.CONFIGURATION_TYPE,
			children: [
				{ id: 'CUSTOM', name: 'Custom' },
				{ id: 'BY_GROUP', name: 'By Group' },
			],
		},
		{
			id: 'settingsStatus',
			name: FILTER_TYPE.CONFIGURATION_STATUS,
			children: [
				{ id: 'OUT_OF_DATE', name: 'Out of Date' },
				{ id: 'PENDING', name: 'Pending' },
				{ id: 'APPLIED', name: 'Applied' },
			],
		},
	],
	CORE_HISTORY: [
		{
			id: 'status',
			name: 'Status',
			children: [PROCESSING_HISTORY_STATUS.allowed, PROCESSING_HISTORY_STATUS.blocked, PROCESSING_HISTORY_STATUS.processing],
		},
		{
			id: 'workflow',
			name: 'Workflow',
			dynamic: true,
			loading: false,
			children: DEFAULT_DYNAMIC_FILTERS.workflows,
		},
		{
			id: 'result',
			name: 'Result',
			search: true,
			searchTerm: '',
			dynamic: true,
			loading: false,
			children: DEFAULT_DYNAMIC_FILTERS.results,
		},
		{
			id: 'action',
			name: 'Action',
			dynamic: true,
			loading: false,
			children: DEFAULT_DYNAMIC_FILTERS.actions,
		},
	],
	PRODUCT_INSTANCE: [
		{
			id: 'license',
			name: FILTER_TYPE.LICENSE,
			children: [
				{ id: '-1', name: LICENSE.NO_LICENSE },
				{ id: '-2', name: LICENSE.EXPIRED },
				{ id: '30', name: LICENSE.EXPIRING_IN_30_DAYS },
				{ id: '90', name: LICENSE.EXPIRING_IN_90_DAYS },
				{ id: '91', name: LICENSE.EXPIRING_IN_90PLUS_DAYS },
			],
		},
		{
			id: 'status',
			name: 'Status',
			children: [
				{ id: CONNECTION_STATUS.CONNECTED.id, name: CONNECTION_STATUS.CONNECTED.name },
				{ id: CONNECTION_STATUS.DISCONNECTED.id, name: CONNECTION_STATUS.DISCONNECTED.name },
				{ id: CONNECTION_STATUS.NO_LICENSE.id, name: CONNECTION_STATUS.NO_LICENSE.name },
			],
		},
	],
	PRODUCT_INSTANCE_NO_LICENSE: [
		{
			id: 'status',
			name: 'Status',
			children: [
				{ id: CONNECTION_STATUS.CONNECTED.id, name: CONNECTION_STATUS.CONNECTED.name },
				{ id: CONNECTION_STATUS.DISCONNECTED.id, name: CONNECTION_STATUS.DISCONNECTED.name },
			],
		},
	],
	MEDIA_FIREWALL: [
		{
			id: 'status',
			name: 'Status',
			children: [
				{ id: CONNECTION_STATUS.CONNECTED.id, name: CONNECTION_STATUS.CONNECTED.name },
				{ id: CONNECTION_STATUS.DISCONNECTED.id, name: CONNECTION_STATUS.DISCONNECTED.name },
			],
		},
	],
	PRODUCT_INSTANCE_LEVEL1: [
		{
			id: 'license',
			name: FILTER_TYPE.LICENSE,
			children: [
				{ id: '-1', name: LICENSE.NO_LICENSE },
				{ id: '-2', name: LICENSE.EXPIRED },
				{ id: '30', name: LICENSE.EXPIRING_IN_30_DAYS },
				{ id: '90', name: LICENSE.EXPIRING_IN_90_DAYS },
				{ id: '91', name: LICENSE.EXPIRING_IN_90PLUS_DAYS },
			],
		},
		{
			id: 'status',
			name: FILTER_TYPE.CONNECTION_STATUS,
			children: FILTER_STATUS_CHILDREN,
		},
		{
			id: 'settingsType',
			name: FILTER_TYPE.CONFIGURATION_TYPE,
			children: [
				{ id: 'CUSTOM', name: 'Custom' },
				{ id: 'BY_GROUP', name: 'By Group' },
			],
		},
		{
			id: 'settingsStatus',
			name: FILTER_TYPE.CONFIGURATION_STATUS,
			children: [
				{ id: 'OUT_OF_DATE', name: 'Out of Date' },
				{ id: 'PENDING', name: 'Pending' },
				{ id: 'APPLIED', name: 'Applied' },
			],
		},
	],
	KIOSK_LINUX_REPORT: [
		{
			id: 'status',
			name: FILTER_TYPE.CONNECTION_STATUS,
			children: [
				{ id: 'connected', name: 'Connected' },
				{ id: 'disconnected', name: 'Disconnected' },
				{ id: 'inactive', name: 'Inactive' },
			],
		},
	],
	USER_MANAGEMENT_ONCLOUD: [
		{
			id: 'roleIds',
			name: 'Role',
			children: [
				{
					id: CUSTOM_USER_ROLE_ID,
					name: 'Custom Roles',
					children: [],
				},
			],
		},
		{
			id: 'status',
			name: 'Status',
			children: [
				{ id: 'Invited', name: 'Invited' },
				{ id: 'Active', name: 'Active' },
				// { id: 'Inactive', name: 'InActive' },
			],
		},
	],
	USER_MANAGEMENT_ONPREM: [
		{
			id: 'roleIds',
			name: 'Role',
			children: [
				{
					id: CUSTOM_USER_ROLE_ID,
					name: 'Custom Roles',
					children: [],
				},
			],
		},
		{
			id: 'status',
			name: 'Status',
			children: [
				{ id: 'Invited', name: 'Invited' },
				{ id: 'Active', name: 'Active' },
				// { id: 'Inactive', name: 'InActive' },
			],
		},
		{
			id: 'directoryType',
			name: 'User Directory',
			children: [
				{ id: 'LOCAL', name: 'Local' },
				{ id: 'AD', name: 'Active Directory' },
				{ id: 'SAML', name: 'Single Sign-On (SSO)' },
			],
		},
	],
	USER_DIRECTORIES: [
		{
			id: 'roleIds',
			name: 'Role',
			children: [
				{
					id: CUSTOM_USER_ROLE_ID,
					name: 'Custom Roles',
					children: [],
				},
			],
		},
		{
			id: 'userDirectory',
			name: 'User Directory',
			children: [{ id: 'LOCAL', name: 'Local' }],
		},
		{
			id: 'status',
			name: 'Status',
			children: [
				{ id: '1', name: 'Enabled' },
				{ id: '0', name: 'Disabled' },
			],
		},
	],
	LICENSE_PRODUCT: [
		{
			id: 'license',
			name: FILTER_TYPE.LICENSE,
			children: [
				{ id: '-1', name: LICENSE.NO_LICENSE },
				{ id: '-2', name: LICENSE.EXPIRED },
				{ id: '30', name: LICENSE.EXPIRING_IN_30_DAYS },
				{ id: '90', name: LICENSE.EXPIRING_IN_90_DAYS },
				{ id: '91', name: LICENSE.EXPIRING_IN_90PLUS_DAYS },
			],
		},
		{
			id: 'type',
			name: 'Activation Type',
			children: [
				{ id: 'offline', name: 'Offline' },
				{ id: 'online', name: 'Online' },
			],
		},
		{
			id: 'status',
			name: 'Status',
			children: [
				{ id: 'active', name: 'Active' },
				{ id: 'inactive', name: 'Inactive' },
			],
		},
	],

	KIOSK_INSTANCE_ACKNOWLEDGE: [
		{
			id: 'age',
			name: 'Time',
			children: [
				{ id: 1, name: 'Last 24 hours' },
				{ id: 7, name: 'Last 7 days' },
				{ id: 30, name: 'Last 30 days' },
				{ id: 365, name: 'Last 12 months' },
				{
					id: 0,
					name: 'Custom',
					custom: {
						parentName: 'age',
						parentValue: 7,
						fromName: 'timestampFrom',
						toName: 'timestampTo',
						value: 7,
					},
				},
			],
		},
		{
			id: 'status',
			name: 'Status',
			children: [
				{ id: 'QUEUED', name: 'Queued' },
				{ id: 'IN_PROGRESS', name: 'In Progress' },
				{ id: 'COMPLETED', name: 'Completed' },
				{ id: 'FAILED', name: 'Failed' },
			],
		},
	],

	MDICAP_PROCESSING_HISTORY: [
		{
			type: 'radio',
			id: 'age',
			name: 'Time',
			children: [
				{ id: 1, name: 'Last 24 hours' },
				{ id: 7, name: 'Last 7 days' },
				{ id: 30, name: 'Last 30 days' },
				{ id: 365, name: 'Last 12 months' },
				{
					id: 0,
					name: 'Custom',
					custom: {
						parentName: 'age',
						parentValue: 7,
						fromName: 'timestampFrom',
						toName: 'timestampTo',
						value: 7,
					},
				},
			],
		},
	],

	MDCORE_PROCESSING_HISTORY: [
		{
			type: 'radio',
			id: 'age',
			name: 'Time',
			selectedChild: DEFAULT_TIME_RANGE_VALUE,
			children: [
				{ id: 1, name: 'Last 24 hours' },
				{ id: 7, name: 'Last 7 days' },
				{ id: 30, name: 'Last 30 days' },
				{ id: 365, name: 'Last 12 months' },
				{
					id: 0,
					name: 'Custom',
					custom: {
						parentName: 'age',
						parentValue: 7,
						fromName: 'timestampFrom',
						toName: 'timestampTo',
						value: 7,
					},
				},
			],
		},
		{
			type: 'checkbox',
			id: 'status',
			name: 'Status',
			children: [PROCESSING_HISTORY_STATUS.allowed, PROCESSING_HISTORY_STATUS.blocked, PROCESSING_HISTORY_STATUS.processing],
		},
	],
};

export const LICENSE_PERPETUAL = {
	id: 'license',
	name: FILTER_TYPE.LICENSE,
	children: [
		{ id: '-1', name: LICENSE.NO_LICENSE },
		{ id: 'perpetual', name: LICENSE.PERPETUAL },
	],
};
