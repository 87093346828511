import { abbreviateNumber, endTruncate } from '@app/shared/utils';
import { gridChart } from '../metadefender-kiosk/overview/dashboard/utils';
import { AxisChartOptions, NonAxisChartOptions } from '../models';
import dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

import * as moment_ from 'moment';
const moment = moment_;

export const SESSION_FAILED_REASON = {
	mediaError: 'Could not open file',
	invalidManifest: 'Media Manifest invalid!',
	expiredManifest: 'Media Manifest expired!',
	noManifest: 'No manifest',
	earlyMediaEjection: 'Primary media removed during scan',
	other: 'Other Issues',
};

export enum FILTER_ENUM {
	ONEDAY = '24H',
	SEVENDAYS = '7D',
	ONEMONTH = '30D',
	ONEYEAR = '12M',
	CUSTOM = 'CUSTOM',
}

export const DASHBOARD_COLORS = {
	BLUE: '#3E87F8',
	GREY: '#A2B2D2',
	ORANGE: '#FD760D',
	RED: '#D00300',
};

export const MEDIA_TYPE_ICON = {
	'USB-C': 'usbc',
	'USB-A': 'usba',
	'SD Card': 'sd_card',
	'MicroSD Card': 'micro_sd',
	'Compact Flash': 'compact_flash_card',
	UNKNOW: 'unknow_media_type',
};

export const crosshairs = {
	show: true,
	width: 5,
	position: 'front',
	opacity: 1,
	stroke: {
		color: 'white',
		width: 2,
		dashArray: 0,
	},
	fill: {
		type: 'solid',
		color: DASHBOARD_COLORS.BLUE,
	},
	dropShadow: {
		enabled: false,
		top: 0,
		left: 0,
		blur: 1,
		opacity: 0.4,
	},
};

export const SCAN_RESULT_CHART_SERIES = {
	BLOCKED: {
		label: 'Blocked',
		color: DASHBOARD_COLORS.RED,
	},
	CONCEALED: {
		label: 'Concealed',
		color: DASHBOARD_COLORS.ORANGE,
	},
	ALLOWED: {
		label: 'Allowed',
		color: DASHBOARD_COLORS.GREY,
	},
};

export const TOTAL_SESSION_CHART_SERIES = {
	SUCCESS: {
		label: 'Success',
		color: DASHBOARD_COLORS.GREY,
		sort: 0,
	},
	FAIL: {
		label: 'Failed',
		color: DASHBOARD_COLORS.RED,
		sort: 1,
	},
};

export const VERDICT_CHART_SERIES = {
	Infected: {
		key: 'Infected',
		color: '#D00300',
	},
	Allowed: {
		key: 'Allowed',
		color: '#B5C1DB',
	},
	1: {
		color: '#FD760D',
	},
	2: {
		color: '#FDB00D',
	},
	3: {
		color: '#4C5560',
	},
	DEFAULT: {
		color: '#4C5560',
	},
};

const DEFAULT_FONT_STYLE = 'Roboto, Arial, sans-serif';

export const BLOCKED_CHART_OPTION: Partial<AxisChartOptions> = {
	series: [],
	chart: {
		type: 'bar',
		height: 250,
		width: '100%',
		toolbar: {
			show: false,
		},
	},
	plotOptions: {
		bar: {
			horizontal: true,
			barHeight: 20,
			colors: {
				backgroundBarColors: ['#F8F8F8'],
				backgroundBarOpacity: 0.5,
				backgroundBarRadius: 4,
			},
		},
	},
	dataLabels: {
		enabled: false,
	},
	xaxis: {
		labels: {
			show: false,
		},
		axisTicks: {
			show: false,
		},
		axisBorder: {
			show: false,
		},
	},
	yaxis: {
		show: true,
		labels: {
			style: {
				fontSize: '13px',
				fontFamily: DEFAULT_FONT_STYLE,
			},
			formatter: function (val, opts): any {
				return `${opts?.w?.globals?.series?.[0]?.[opts?.dataPointIndex]}`;
			},
		},
	},
	grid: {
		borderColor: 'red',
		position: 'back',
		xaxis: {
			lines: {
				show: false,
			},
		},
		yaxis: {
			lines: {
				show: false,
			},
		},
	},
	fill: {
		type: 'gradient',
		colors: ['#CF0402'],
		gradient: {
			shade: 'dark',
			type: 'horizontal',
			shadeIntensity: 0.5,
			gradientToColors: ['#BEC2CC'],
			inverseColors: true,
			opacityFrom: 1,
			opacityTo: 1,
			stops: [0, 100],
		},
	},
	tooltip: {
		enabled: false,
	},
};

export const PROCESSED_CHART_OPTION: Partial<NonAxisChartOptions> = {
	series: [],
	colors: ['#D00300', '#FD760D', '#FDB00D', '#4C5560', '#B5C1DB'],
	labels: [],
	chart: {
		// width: '90%',
		width: 580,
		type: 'donut',
		offsetX: -15,
		offsetY: 20,
		toolbar: {
			show: false,
		},
	},
	legend: {
		show: true,
		position: 'right',
		fontFamily: DEFAULT_FONT_STYLE,
		fontSize: '13',
		fontWeight: '500',
		horizontalAlign: 'center',
		floating: false,
		// tooltipHoverFormatter(legendName, opts) {
		// 	return '333';
		// },
		markers: {
			radius: 0,
			width: 2,
			height: 20,
		},
		itemMargin: {
			horizontal: 5,
			vertical: 0,
		},
		formatter: function (seriesName, opts) {
			return `<div class="flex flex-space-between">
					<div>${abbreviateNumber(opts.w.config.series[opts.seriesIndex])}</div>
					<div>${endTruncate(seriesName, 20)}</div>
				</div>`;
		},
	},
	plotOptions: {
		pie: {
			donut: {
				size: '60%',
				labels: {
					show: false,
					total: {
						show: false,
						label: '',
					},
					value: {
						show: true,
						fontFamily: DEFAULT_FONT_STYLE,
						offsetY: 0,
						fontSize: '15px',
						fontWeight: 500,
					},
					name: {
						show: false,
						fontFamily: DEFAULT_FONT_STYLE,
					},
				},
			},
		},
	},
	dataLabels: {
		enabled: false,
	},
	stroke: {
		width: 1,
		colors: ['#fff'],
	},
	grid: {},
	states: {
		hover: {
			filter: {
				type: 'darken',
				value: 0.8,
			},
		},
	},
	responsive: [
		{
			breakpoint: 1549.5,
			options: {
				chart: {
					width: 470,
					offsetX: 0,
				},
				legend: {
					position: 'right',
					show: true,
				},
			},
		},

		{
			breakpoint: 1151,
			options: {
				chart: {
					width: 470,
				},
				legend: {
					show: true,
					position: 'right',
				},
				plotOptions: {
					pie: {
						donut: {
							// size: '60%',
							labels: {
								show: false,
								total: {
									show: false,
									label: '',
								},
								value: {
									show: false,
									fontFamily: DEFAULT_FONT_STYLE,
									offsetY: 0,
									fontSize: '15px',
									fontWeight: 500,
								},
								name: {
									show: false,
									fontFamily: DEFAULT_FONT_STYLE,
								},
							},
						},
					},
				},
			},
		},
	],
};

export const SCAN_RESULT_CHART_OPTION: Partial<AxisChartOptions> = {
	series: [],
	colors: [SCAN_RESULT_CHART_SERIES.ALLOWED.color, SCAN_RESULT_CHART_SERIES.CONCEALED.color, SCAN_RESULT_CHART_SERIES.BLOCKED.color],
	chart: {
		type: 'area',
		height: 340,
		stacked: true,
		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false,
		},
		group: 'date-chart',
		id: 'scan',
		width: '100%',
	},
	xaxis: {
		crosshairs,
		tooltip: {
			enabled: false,
		},
	},
	yaxis: {
		show: true,
		labels: {
			formatter: function (value: any) {
				return abbreviateNumber(value);
			},
			maxWidth: 30,
			minWidth: 30,
		},
		tickAmount: 4,
		min: 0,
	},
	legend: {
		show: false,
	},
	dataLabels: {
		enabled: false,
	},
	stroke: {
		curve: 'straight',
		width: [0, 0, 0, 0],
		lineCap: 'round',
	},
	fill: {
		type: 'solid',
		opacity: 1,
	},
	responsive: [
		{
			breakpoint: 480,
			options: {
				chart: {
					width: 600,
				},
			},
		},
	],
	grid: gridChart,
	tooltip: {
		enabled: true,
		shared: true,
		intersect: false,
		x: {
			show: false,
		},
	},
};

export const TOTAL_SESSION_CHART_OPTION: Partial<AxisChartOptions> = {
	series: [],
	colors: [TOTAL_SESSION_CHART_SERIES.SUCCESS.color, TOTAL_SESSION_CHART_SERIES.FAIL.color],
	chart: {
		type: 'area',
		height: 340,
		stacked: true,
		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false,
		},
		group: 'date-chart',
		id: 'session',
		width: '100%',
	},
	xaxis: {
		crosshairs,
		tooltip: {
			enabled: false,
		},
	},
	yaxis: {
		show: true,
		tickAmount: 4,
		min: 0,
		labels: {
			formatter: function (value: any) {
				return abbreviateNumber(value);
			},
			maxWidth: 30,
			minWidth: 30,
		},
	},
	legend: {
		position: 'top',
		show: false,
	},
	dataLabels: {
		enabled: false,
	},
	stroke: {
		curve: 'straight',
		width: [0, 0, 0, 0],
		lineCap: 'round',
	},
	fill: {
		type: 'solid',
		opacity: 1,
	},
	responsive: [
		{
			breakpoint: 480,
			options: {
				chart: {
					width: 600,
				},
			},
		},
	],
	grid: gridChart,
};
