import { environment } from '@env/environment';
import { INDUSTRIAL_FIREWALL_FAMILY, KIOSK_FAMILY, NETWALL_FAMILY, OPSWAT_PRODUCTS } from './product.constant';

/* eslint-disable */
export const URL_CONSTANT = {
	NLV: '/nlv',
	REDIRECT: '/nlv/redirect',
	TCS: '/nlv/tcs',
	UI: '/nlv/ui-components',
	LOGIN: '/nlv/login',
	SYSTEM_UPGRADING: '/nlv/system-upgrading',
	SYSTEM_IMPORTING_FILE: '/nlv/system-importing-file',
	SETUP_WIZARD: '/nlv/setup-wizard',
	ERROR_PAGE: '/nlv/error',
	DASHBOARD: {
		OVERVIEW: '/dashboard/overview',
		DASHBOARD: '/dashboard',
	},
	INVENTORY: {
		INVENTORY: '/inventory',
		KIOSK_OVERVIEW: '/metadefender-kiosk/overview',
		MK5: {
			INSTANCE_LIST: '/metadefender-kiosk/l-series',
		},
		KIOSK: {
			INSTANCE_LIST: '/metadefender-kiosk/k-series',
		},
		VAULT: {
			INSTANCE_LIST: '/metadefender-vault',
		},
		OWC: {
			INSTANCE_LIST: '/metadefender-client',
		},
		MDD: {
			INSTANCE_LIST: '/metadefender-drive',
		},
		MDCORE: {
			INSTANCE_LIST: '/metadefender-core',
		},
		MDCORE_OVERVIEW: '/metadefender-core/overview',
		UF3: {
			INSTANCE_LIST: '/metadefender-media-firewall',
		},
		MDICAP: {
			INSTANCE_LIST: '/metadefender-icap',
		},
		MA: {
			INSTANCE_LIST: '/metaaccess',
		},
		FSCAN: {
			INSTANCE_LIST: '/filescan-sandbox',
		},
		NEURALYZER: {
			INSTANCE_LIST: '/neuralyzer',
		},
		OTFUSE: {
			INSTANCE_LIST: '/industrial-firewall/otfuse',
		},
		MAOT: {
			INSTANCE_LIST: '/maot',
		},
		MDSS: {
			INSTANCE_LIST: '/mdss',
		},
		MDEMAIL: {
			INSTANCE_LIST: '/mdemail',
		},

		// Unidirectional
		NWUSGBLUE: {
			INSTANCE_LIST: '/netwall/unidirectional-security-gateway-blue',
		},
		NWUSGRED: {
			INSTANCE_LIST: '/netwall/unidirectional-security-gateway-red',
		},

		// Bilateral
		NWBSGBLUE: {
			INSTANCE_LIST: '/netwall/bilateral-security-gateway-blue',
		},
		NWBSGRED: {
			INSTANCE_LIST: '/netwall/bilateral-security-gateway-red',
		},

		// Threat Protection
		NWTPBLUE: {
			INSTANCE_LIST: '/netwall/threat-protection-blue',
		},
		NWTPRED: {
			INSTANCE_LIST: '/netwall/threat-protection-red',
		},

		// Optical Diode
		NWODBLUE: {
			INSTANCE_LIST: '/netwall/optical-diode-blue',
		},
		NWODRED: {
			INSTANCE_LIST: '/netwall/optical-diode-red',
		},
		MDSSC: {
			INSTANCE_LIST: '/supply-chain',
		},
		DASHBOARD: '/dashboard',
		MDIF: {
			INSTANCE_LIST: '/industrial-firewall/mdif',
		},
		// Transfer Guard
		NWTGBLUE: {
			INSTANCE_LIST: '/netwall/transfer-guard-blue',
		},
		NWTGRED: {
			INSTANCE_LIST: '/netwall/transfer-guard-red',
		},

	},
	FIREWALL: {
		FIREWALL: '/metadefender-media-firewall',
	},
	LOG: '/events-history',
	SETTINGS: {
		ACCOUNT: '/settings/global/account',
	},
	USER_PROFILE: '/user-profile',
	USER_MANAGEMENT: {
		USER_MANAGEMENT: '/user-management',
		USER: '/user-management/users',
		ROLES: '/user-management/roles',
		ADD_NEW_USERS: '/user-management/users-&-groups/add',
		ADD_NEW_ROLE: '/user-management/roles/add',
		VIEW_ROLE: '/user-management/roles/view',
		DIRECTORY: '/user-management/directories',
		ADD_NEW_DIRECTORY: '/user-management/directories/add/',
		DIRECTORY_DETAIL: '/user-management/directories/edit/',
	},
	PERMISSION_DENIED: '/permission-denied',
	NOT_FOUND: '/page-not-found',
	EMPTY_INVENTORY: '/empty',
	LICENSES: '/licenses',
	CLOUD_LICENSES: '/cloud-licenses',
	SUPPORT: '/supports',
	ONPREM_RESET_PASSWORD: '/nlv/reset-password',
	NON_BACKGROUND: {
		NON_BACKGROUND: '/non-bg',
		TERMS_AND_POLICY: '/terms-and-policy',
	},
	MY_OPSWAT_LICENSE: '/licenses/my-opswat',
	PRODUCT_LICENSE: '/licenses/l-series',
};
export const PAGE_URLS: any = {
	inventory: '/inventory',
	userManagement: '/user-management',
	eventsHistory: '/events-history',
	settings: 'settings',
	userProfile: '/userProfile',
	licenses: '/licenses',
	global: '/settings/global/account',
	products: '/settings/products/products',
	reportsNotifications: '/settings/reports',
	tools: '/settings/tools',
	integrations: '/settings/integrations',
	permissionDenied: '/permission-denied',
};

export const DOCUMENT_LINK = {
	CONFIGURATION_NOT_LOADED: 'https://docs.opswat.com/product-management/knowledge-base/a-product-instance-s-configuration-not-loaded',
};

export const MY_OPSWAT_URL = {
	DOWNLOADS: '/product-downloads',
	LICENSE: '/license-management/my-organization',
	ACTIVATE_LICENSE: '/dialog?name=ACTIVATE_LICENSE',
	ORGANIZATION: '/my-organization/general-information',
	FEEDBACK: '/dialog?name=FEEDBACK',
	MYINFO: '/my-information/personal-information',
	REPORT: '/report-false-detection/submit',
	SUPPORT_SERVICES: '/support',
};

export const EXTERNAL_URL = {
	SUPPORT: 'https://go2.opswat.com/login?sc=0LE4X000000k9dd',
	ACADEMY: 'https://opswatacademy.com',
	DOCUMTATION: 'https://docs.opswat.com',
	CONTACT: 'https://www.opswat.com/contact',
	SYSTEM_STATUS: 'https://trust.opswat.com/?utm_source=embed',
	CHANNEL: 'https://opswat.allbound.com/?idp=2',
};

export const CLOUD_PRODUCT = {
	METADEFENDER_CLOUD: 'https://metadefender.opswat.com/login',
	FILESCAN: 'https://filescan.io',
	EMAIL: 'https://www.opswat.com/solutions/email-security/cloud-email-gateway',
};

export const CLOUD_PRODUCT_MENU_NAME = {
	METADEFENDER_CLOUD: 'metadefender-cloud',
	FILESCAN: 'filescan-io',
	EMAIL: 'metadefender-cloud-email',
	METADEFENDER_IT_ACCESS: 'metadefender-it-access',
};

export const MENU_NAME = {
	PRODUCT_MANAGEMENT: 'product-management',
	INVENTORY: 'inventory',
	CHANNEL_PARTNER: 'channel-partner',
	MY_ORG_MENU_NAME: 'my-organizations',
	CLOUD_PRODUCT_MENU_NAME: 'cloud-services',
};
export const NLV_URLS = [
	URL_CONSTANT.UI,
	URL_CONSTANT.TCS,
	URL_CONSTANT.REDIRECT,
	URL_CONSTANT.LOGIN,
	URL_CONSTANT.ERROR_PAGE,
	URL_CONSTANT.SYSTEM_UPGRADING,
	URL_CONSTANT.SYSTEM_IMPORTING_FILE,
	URL_CONSTANT.SETUP_WIZARD,
];

export const MENU_ITEMS = [
	{
		icon: 'home',
		label: 'Home',
		name: 'Home',
		externalLink: environment.myOpswat,
		target: '_self',
		hidden: false,
	},
	{
		icon: 'dashboard-engine',
		label: 'Central Management',
		name: 'product-management',
		link: '/',
		hidden: false,
		showChildren: true,
		subItems: [
			{
				label: 'Products',
				name: 'inventory',
				link: URL_CONSTANT.INVENTORY.INVENTORY,
				hidden: false,
				showChildren: true,
				subItems: [
					{
						label: 'MetaDefender Kiosk',
						defaultLabel: KIOSK_FAMILY.FULL_NAME,
						permissionKey: KIOSK_FAMILY.KEY,
						name: 'metadefender-kiosk',
						link: '/inventory/metadefender-kiosk/overview',
						productFamily: true,
						defaultLink: `${URL_CONSTANT.INVENTORY.INVENTORY}${URL_CONSTANT.INVENTORY.KIOSK_OVERVIEW}`,
						hidden: false,
						showChildren: true,
						subItems: [
							{
								label: 'K-Series',
								labelFull: OPSWAT_PRODUCTS.KIOSK.FULL_NAME,
								permissionKey: OPSWAT_PRODUCTS.KIOSK.KEY,
								name: 'metadefender-kiosk-k3001',
								link: '/inventory/metadefender-kiosk/k-series',
								hidden: false,
							},
							{
								label: 'L-Series',
								labelFull: OPSWAT_PRODUCTS.MK5.FULL_NAME,
								permissionKey: OPSWAT_PRODUCTS.MK5.KEY,
								name: 'metadefender-kiosk-l1001',
								link: '/inventory/metadefender-kiosk/l-series',
								hidden: false,
							},
						],
					},
					{
						label: OPSWAT_PRODUCTS.MDCORE.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.MDCORE.KEY,
						name: 'metadefender-core',
						link: '/inventory/metadefender-core',
						hidden: false,
						showChildren: false,
						subItems: [],
					},
					{
						label: OPSWAT_PRODUCTS.MDD.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.MDD.KEY,
						name: 'metadefender-drive',
						link: '/inventory/metadefender-drive',
						hidden: false,
						showChildren: false,
						subItems: [],
					},
					{
						label: OPSWAT_PRODUCTS.UF3.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.UF3.KEY,
						name: 'metadefender-media-firewall',
						link: '/inventory/metadefender-media-firewall',
						hidden: false,
						showChildren: false,
						subItems: [],
					},
					{
						label: OPSWAT_PRODUCTS.MDICAP.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.MDICAP.KEY,
						name: 'metadefender-icap',
						link: '/inventory/metadefender-icap',
						hidden: false,
						showChildren: false,
						subItems: [],
					},
					{
						label: OPSWAT_PRODUCTS.VAULT.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.VAULT.KEY,
						name: 'metadefender-vault',
						link: '/inventory/metadefender-vault',
						hidden: false,
						showChildren: false,
						subItems: [],
					},
					{
						label: OPSWAT_PRODUCTS.FSCAN.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.FSCAN.KEY,
						name: 'filescan-sandbox',
						link: '/inventory/filescan-sandbox',
						hidden: false,
						showChildren: false,
						subItems: [],
					},
					{
						label: OPSWAT_PRODUCTS.NEURALYZER.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.NEURALYZER.KEY,
						name: 'neuralyzer',
						link: '/inventory/neuralyzer',
						hidden: false,
						showChildren: false,
						subItems: [],
					},
					{
						label: INDUSTRIAL_FIREWALL_FAMILY.FULL_NAME,
						defaultLabel: INDUSTRIAL_FIREWALL_FAMILY.FULL_NAME,
						permissionKey: INDUSTRIAL_FIREWALL_FAMILY.KEY,
						name: 'industrial-firewall',
						link: null,
						productFamily: true,
						hidden: false,
						showChildren: true,
						subItems: [
							{
								label: OPSWAT_PRODUCTS.OTFUSE.SHORT_NAME,
								labelFull: OPSWAT_PRODUCTS.OTFUSE.FULL_NAME,
								permissionKey: OPSWAT_PRODUCTS.OTFUSE.KEY,
								name: 'otfuse',
								link: '/inventory/industrial-firewall/otfuse',
								hidden: false,
								showChildren: false,
							},
							{
								label: OPSWAT_PRODUCTS.MDIF.SHORT_NAME,
								labelFull: OPSWAT_PRODUCTS.MDIF.FULL_NAME,
								permissionKey: OPSWAT_PRODUCTS.MDIF.KEY,
								name: 'mdif',
								link: '/inventory/industrial-firewall/mdif',
								hidden: false,
								showChildren: false,
							},
						],
					},
					{
						label: OPSWAT_PRODUCTS.MDEMAIL.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.MDEMAIL.KEY,
						name: 'mdemail',
						link: '/inventory/mdemail',
						hidden: false,
						showChildren: false,
						subItems: [],
					},
					{
						label: OPSWAT_PRODUCTS.MDSS.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.MDSS.KEY,
						name: 'mdss',
						link: '/inventory/mdss',
						hidden: false,
						showChildren: false,
						subItems: [],
					},
					{
						label: NETWALL_FAMILY.FULL_NAME,
						permissionKey: NETWALL_FAMILY.KEY,
						defaultLabel: NETWALL_FAMILY.FULL_NAME,
						productFamily: true,
						defaultLink: null,
						name: 'Netwall',
						link: null,
						hidden: false,
						showChildren: false,
						subItems: [
							// Unidirectional
							{
								label: OPSWAT_PRODUCTS.NWUSGBLUE.SHORT_NAME,
								labelFull: OPSWAT_PRODUCTS.NWUSGBLUE.FULL_NAME,
								permissionKey: OPSWAT_PRODUCTS.NWUSGBLUE.KEY,
								name: '',
								link: '/inventory/netwall/unidirectional-security-gateway-blue',
								hidden: false,
							},
							{
								label: OPSWAT_PRODUCTS.NWUSGRED.SHORT_NAME,
								labelFull: OPSWAT_PRODUCTS.NWUSGRED.FULL_NAME,
								permissionKey: OPSWAT_PRODUCTS.NWUSGRED.KEY,
								name: '',
								link: '/inventory/netwall/unidirectional-security-gateway-red',
								hidden: false,
							},

							// Bilateral
							{
								label: OPSWAT_PRODUCTS.NWBSGBLUE.SHORT_NAME,
								labelFull: OPSWAT_PRODUCTS.NWBSGBLUE.FULL_NAME,
								permissionKey: OPSWAT_PRODUCTS.NWBSGBLUE.KEY,
								name: '',
								link: '/inventory/netwall/bilateral-security-gateway-blue',
								hidden: false,
							},
							{
								label: OPSWAT_PRODUCTS.NWBSGRED.SHORT_NAME,
								labelFull: OPSWAT_PRODUCTS.NWBSGRED.FULL_NAME,
								permissionKey: OPSWAT_PRODUCTS.NWBSGRED.KEY,
								name: '',
								link: '/inventory/netwall/bilateral-security-gateway-red',
								hidden: false,
							},

							// Threat Protection
							{
								label: OPSWAT_PRODUCTS.NWTPBLUE.SHORT_NAME,
								labelFull: OPSWAT_PRODUCTS.NWTPBLUE.FULL_NAME,
								permissionKey: OPSWAT_PRODUCTS.NWTPBLUE.KEY,
								name: '',
								link: '/inventory/netwall/threat-protection-blue',
								hidden: false,
							},
							{
								label: OPSWAT_PRODUCTS.NWTPRED.SHORT_NAME,
								labelFull: OPSWAT_PRODUCTS.NWTPRED.FULL_NAME,
								permissionKey: OPSWAT_PRODUCTS.NWTPRED.KEY,
								name: '',
								link: '/inventory/netwall/threat-protection-red',
								hidden: false,
							},

							// Optical Diode
							{
								label: OPSWAT_PRODUCTS.NWODBLUE.SHORT_NAME,
								labelFull: OPSWAT_PRODUCTS.NWODBLUE.FULL_NAME,
								permissionKey: OPSWAT_PRODUCTS.NWODBLUE.KEY,
								name: '',
								link: '/inventory/netwall/optical-diode-blue',
								hidden: false,
							},
							{
								label: OPSWAT_PRODUCTS.NWODRED.SHORT_NAME,
								labelFull: OPSWAT_PRODUCTS.NWODRED.FULL_NAME,
								permissionKey: OPSWAT_PRODUCTS.NWODRED.KEY,
								name: '',
								link: '/inventory/netwall/optical-diode-red',
								hidden: false,
							},
							// Transfer Guard
							{
								label: OPSWAT_PRODUCTS.NWTGBLUE.SHORT_NAME,
								labelFull: OPSWAT_PRODUCTS.NWTGBLUE.FULL_NAME,
								permissionKey: OPSWAT_PRODUCTS.NWTGBLUE.KEY,
								name: '',
								link: '/inventory/netwall/transfer-guard-blue',
								hidden: false,
							},
							{
								label: OPSWAT_PRODUCTS.NWTGRED.SHORT_NAME,
								labelFull: OPSWAT_PRODUCTS.NWTGRED.FULL_NAME,
								permissionKey: OPSWAT_PRODUCTS.NWTGRED.KEY,
								name: '',
								link: '/inventory/netwall/transfer-guard-red',
								hidden: false,
							},
						],
					},
					{
						label: OPSWAT_PRODUCTS.MDSSC.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.MDSSC.KEY,
						name: 'mdssc',
						link: '/inventory/supply-chain',
						hidden: false,
						showChildren: false,
						subItems: [],
					},
				],
			},
			{
				icon: 'users',
				label: 'User Permission',
				name: 'userManagement',
				link: URL_CONSTANT.USER_MANAGEMENT.USER_MANAGEMENT,
				hidden: false,
				showChildren: true,
				subItems: [],
			},
			{
				icon: 'history',
				label: 'Events History',
				name: 'eventsHistory',
				link: URL_CONSTANT.LOG,
				hidden: false,
				showChildren: true,
				subItems: [],
			},
			{
				icon: 'policy',
				label: 'Licenses',
				name: 'cloud-licenses',
				link: '/cloud-licenses',
				hidden: false,
				showChildren: true,
				subItems: [],
			},
			{
				icon: 'settings',
				label: 'Settings',
				name: 'settings',
				link: '/settings',
				hidden: false,
				showChildren: true,
				subItems: [
					{
						label: 'Global',
						permissionKey: 'global',
						name: 'global',
						link: '/settings/global',
						hidden: false,
					},
					{
						label: 'Products',
						permissionKey: 'products',
						name: 'products',
						link: '/settings/products',
						hidden: false,
					},
					{
						label: 'Reports & Notifications',
						permissionKey: 'reports',
						name: 'reports',
						link: '/settings/reports',
						hidden: false,
					},
					{
						label: 'Integrations',
						permissionKey: 'integrations',
						name: 'integrations',
						link: '/settings/integrations',
						hidden: false,
					},
					{
						label: 'Tools',
						permissionKey: 'tools',
						name: 'tools',
						link: '/settings/tools',
						hidden: false,
					},
				],
			},
		],
	},
	{
		icon: 'cloud',
		label: 'Cloud Services',
		name: MENU_NAME.CLOUD_PRODUCT_MENU_NAME,
		hidden: false,
		showChildren: true,
		subItems: [
			{
				label: 'MetaDefender Cloud',
				name: CLOUD_PRODUCT_MENU_NAME.METADEFENDER_CLOUD,
				externalLink: CLOUD_PRODUCT.METADEFENDER_CLOUD,
				target: '_blank',
				hidden: false,
			},
			{
				label: 'MetaDefender IT Access',
				name: CLOUD_PRODUCT_MENU_NAME.METADEFENDER_IT_ACCESS,
				hidden: false,
				showChildren: false,
				externalLink: '',
				target: '_blank',
				subItems: [],
			},
			{
				label: 'Filescan.io',
				name: 'filescan-io',
				externalLink: CLOUD_PRODUCT.FILESCAN,
				target: '_blank',
				hidden: false,
			},
			{
				label: 'Cloud Email Security',
				name: 'metadefender-cloud-email',
				externalLink: CLOUD_PRODUCT.EMAIL,
				target: '_blank',
				hidden: false,
			},
		],
	},
	{
		icon: 'download',
		label: 'Product Downloads',
		name: 'downloads',
		externalLink: environment.myOpswat + MY_OPSWAT_URL.DOWNLOADS,
		target: '_self',
		hidden: false,
	},
	{
		icon: 'policy',
		label: 'License Management',
		name: 'licensed-products',
		externalLink: environment.myOpswat + MY_OPSWAT_URL.LICENSE,
		target: '_self',
		hidden: false,
	},
	{
		icon: 'organisations',
		label: 'My Organization',
		name: 'my-organizations',
		externalLink: environment.myOpswat + MY_OPSWAT_URL.ORGANIZATION,
		target: '_self',
		hidden: false,
	},
	{
		icon: 'approval',
		label: 'Support',
		name: 'support-services',
		externalLink: environment.myOpswat + MY_OPSWAT_URL.SUPPORT_SERVICES,
		target: '_self',
		hidden: false,
	},
	{
		icon: 'users',
		label: 'OPSWAT Partner Portal ',
		name: MENU_NAME.CHANNEL_PARTNER,
		externalLink: EXTERNAL_URL.CHANNEL,
		target: '_blank',
		hidden: true,
	},
];

const EXTERNAL_MENU_ICON = 'external-link';
export const EXTERNAL_MENU = [
	{
		icon: 'academy',
		label: 'OPSWAT Academy',
		name: 'opswat-academy',
		externalLink: EXTERNAL_URL.ACADEMY,
		target: '_blank',
		hidden: false,
		rightIcon: EXTERNAL_MENU_ICON,
	},
	{
		icon: 'life-saver',
		label: 'Request Support',
		name: 'request-support',
		externalLink: EXTERNAL_URL.SUPPORT,
		target: '_blank',
		hidden: false,
		rightIcon: EXTERNAL_MENU_ICON,
	},
	{
		icon: 'file-multiplev2',
		label: 'Product Documentation',
		name: 'product-documentation',
		externalLink: EXTERNAL_URL.DOCUMTATION,
		target: '_blank',
		hidden: false,
		rightIcon: EXTERNAL_MENU_ICON,
	},
	{
		icon: 'package',
		label: 'OPSWAT Channel',
		name: 'opswat-channel',
		externalLink: EXTERNAL_URL.CHANNEL,
		target: '_blank',
		hidden: false,
		rightIcon: EXTERNAL_MENU_ICON,
	},
	{
		icon: 'inbox',
		label: 'Contact OPSWAT',
		name: 'contact-opswat',
		externalLink: EXTERNAL_URL.CONTACT,
		target: '_blank',
		hidden: false,
		rightIcon: EXTERNAL_MENU_ICON,
	},
	{
		icon: 'groups',
		label: 'All Systems Operational',
		name: 'operational',
		externalLink: EXTERNAL_URL.SYSTEM_STATUS,
		target: '_blank',
		hidden: false,
		rightIcon: EXTERNAL_MENU_ICON,
	},
];

export const USER_INTERACTION_ITEMS = [
	{
		icon: 'file-text',
		label: 'Report False Detection',
		name: 'report',
		externalLink: environment.myOpswat + MY_OPSWAT_URL.REPORT,
		hidden: false,
	},
	{
		icon: 'faq',
		label: 'Provide Feedback',
		name: 'feedback',
		externalLink: environment.myOpswat + MY_OPSWAT_URL.FEEDBACK,
		hidden: false,
	},
];
export const PERMISSION_URL = {
	PERMISSION_DENIED: 'permission-denied',
	INSTANCE_PERMISSION_DENIED: 'instances/permission-denied',
	GROUP_PERMISSION_DENIED: 'groups/permission-denied',
	GROUP_DETAIL_INSTANCE_PERMISSION_DENIED: 'groups/detail/:groupId/instance/permission-denied',
	NEW_GROUP_PERMISSION_DENIED: 'groups/add-new-group/permission-denied',
};

/* eslint-enable */

export const ONPREM_MENU_ITEMS = [
	// {
	// 	icon: 'home',
	// 	label: 'Home',
	// 	name: 'Home',
	// 	externalLink: environment.myOpswat,
	// 	hidden: false,
	// },
	{
		icon: 'inventory',
		label: 'Products',
		name: 'inventory',
		link: URL_CONSTANT.INVENTORY.INVENTORY,
		hidden: false,
		showChildren: true,
		subItems: [
			{
				label: 'MetaDefender Kiosk',
				defaultLabel: KIOSK_FAMILY.FULL_NAME,
				permissionKey: KIOSK_FAMILY.KEY,
				name: 'metadefender-kiosk',
				link: '/inventory/metadefender-kiosk/overview',
				productFamily: true,
				defaultLink: `${URL_CONSTANT.INVENTORY.INVENTORY}${URL_CONSTANT.INVENTORY.KIOSK_OVERVIEW}`,
				hidden: false,
				showChildren: true,
				subItems: [
					{
						label: 'K-Series',
						labelFull: OPSWAT_PRODUCTS.KIOSK.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.KIOSK.KEY,
						name: 'metadefender-kiosk-k3001',
						link: '/inventory/metadefender-kiosk/k-series',
						hidden: false,
					},
					{
						label: 'L-Series',
						labelFull: OPSWAT_PRODUCTS.MK5.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.MK5.KEY,
						name: 'metadefender-kiosk-l1001',
						link: '/inventory/metadefender-kiosk/l-series',
						hidden: false,
					},
				],
			},
			{
				label: OPSWAT_PRODUCTS.MDCORE.FULL_NAME,
				permissionKey: OPSWAT_PRODUCTS.MDCORE.KEY,
				name: 'metadefender-core',
				link: '/inventory/metadefender-core',
				hidden: false,
				showChildren: false,
				subItems: [],
			},
			{
				label: OPSWAT_PRODUCTS.MDD.FULL_NAME,
				permissionKey: OPSWAT_PRODUCTS.MDD.KEY,
				name: 'metadefender-drive',
				link: '/inventory/metadefender-drive',
				hidden: false,
				showChildren: false,
				subItems: [],
			},
			{
				label: OPSWAT_PRODUCTS.UF3.FULL_NAME,
				permissionKey: OPSWAT_PRODUCTS.UF3.KEY,
				name: 'metadefender-media-firewall',
				link: '/inventory/metadefender-media-firewall',
				hidden: false,
				showChildren: false,
				subItems: [],
			},
			{
				label: OPSWAT_PRODUCTS.MDICAP.FULL_NAME,
				permissionKey: OPSWAT_PRODUCTS.MDICAP.KEY,
				name: 'metadefender-icap',
				link: '/inventory/metadefender-icap',
				hidden: false,
				showChildren: false,
				subItems: [],
			},
			{
				label: OPSWAT_PRODUCTS.VAULT.FULL_NAME,
				permissionKey: OPSWAT_PRODUCTS.VAULT.KEY,
				name: 'metadefender-vault',
				link: '/inventory/metadefender-vault',
				hidden: false,
				showChildren: false,
				subItems: [],
			},
			{
				label: OPSWAT_PRODUCTS.FSCAN.FULL_NAME,
				permissionKey: OPSWAT_PRODUCTS.FSCAN.KEY,
				name: 'filescan-sandbox',
				link: '/inventory/filescan-sandbox',
				hidden: false,
				showChildren: false,
				subItems: [],
			},
			{
				label: OPSWAT_PRODUCTS.NEURALYZER.FULL_NAME,
				permissionKey: OPSWAT_PRODUCTS.NEURALYZER.KEY,
				name: 'neuralyzer',
				link: '/inventory/neuralyzer',
				hidden: false,
				showChildren: false,
				subItems: [],
			},
			{
				label: INDUSTRIAL_FIREWALL_FAMILY.SHORT_NAME,
				defaultLabel: INDUSTRIAL_FIREWALL_FAMILY.FULL_NAME,
				permissionKey: INDUSTRIAL_FIREWALL_FAMILY.KEY,
				name: 'industrial-firewall',
				link: null,
				productFamily: true,
				hidden: false,
				showChildren: true,
				subItems: [
					{
						label: OPSWAT_PRODUCTS.OTFUSE.SHORT_NAME,
						labelFull: OPSWAT_PRODUCTS.OTFUSE.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.OTFUSE.KEY,
						name: 'otfuse',
						link: '/inventory/industrial-firewall/otfuse',
						hidden: false,
						showChildren: false,
					},
					{
						label: OPSWAT_PRODUCTS.MDIF.SHORT_NAME,
						labelFull: OPSWAT_PRODUCTS.MDIF.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.MDIF.KEY,
						name: 'mdif',
						link: '/inventory/industrial-firewall/mdif',
						hidden: false,
						showChildren: false,
					},
				],
			},
			{
				label: OPSWAT_PRODUCTS.MDEMAIL.FULL_NAME,
				permissionKey: OPSWAT_PRODUCTS.MDEMAIL.KEY,
				name: 'mdemail',
				link: '/inventory/mdemail',
				hidden: false,
				showChildren: false,
				subItems: [],
			},
			{
				label: OPSWAT_PRODUCTS.MDSS.FULL_NAME,
				permissionKey: OPSWAT_PRODUCTS.MDSS.KEY,
				name: 'mdss',
				link: '/inventory/mdss',
				hidden: false,
				showChildren: false,
				subItems: [],
			},
			{
				label: NETWALL_FAMILY.FULL_NAME,
				permissionKey: NETWALL_FAMILY.KEY,
				defaultLabel: NETWALL_FAMILY.FULL_NAME,
				productFamily: true,
				defaultLink: null,
				name: 'Netwall',
				link: null,
				hidden: false,
				showChildren: false,
				subItems: [
					// Unidirectional
					{
						label: OPSWAT_PRODUCTS.NWUSGBLUE.SHORT_NAME,
						labelFull: OPSWAT_PRODUCTS.NWUSGBLUE.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.NWUSGBLUE.KEY,
						name: '',
						link: '/inventory/netwall/unidirectional-security-gateway-blue',
						hidden: false,
					},
					{
						label: OPSWAT_PRODUCTS.NWUSGRED.SHORT_NAME,
						labelFull: OPSWAT_PRODUCTS.NWUSGRED.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.NWUSGRED.KEY,
						name: '',
						link: '/inventory/netwall/unidirectional-security-gateway-red',
						hidden: false,
					},

					// Bilateral
					{
						label: OPSWAT_PRODUCTS.NWBSGBLUE.SHORT_NAME,
						labelFull: OPSWAT_PRODUCTS.NWBSGBLUE.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.NWBSGBLUE.KEY,
						name: '',
						link: '/inventory/netwall/bilateral-security-gateway-blue',
						hidden: false,
					},
					{
						label: OPSWAT_PRODUCTS.NWBSGRED.SHORT_NAME,
						labelFull: OPSWAT_PRODUCTS.NWBSGRED.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.NWBSGRED.KEY,
						name: '',
						link: '/inventory/netwall/bilateral-security-gateway-red',
						hidden: false,
					},

					// Threat Protection
					{
						label: OPSWAT_PRODUCTS.NWTPBLUE.SHORT_NAME,
						labelFull: OPSWAT_PRODUCTS.NWTPBLUE.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.NWTPBLUE.KEY,
						name: '',
						link: '/inventory/netwall/threat-protection-blue',
						hidden: false,
					},
					{
						label: OPSWAT_PRODUCTS.NWTPRED.SHORT_NAME,
						labelFull: OPSWAT_PRODUCTS.NWTPRED.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.NWTPRED.KEY,
						name: '',
						link: '/inventory/netwall/threat-protection-red',
						hidden: false,
					},

					// Optical Diode
					{
						label: OPSWAT_PRODUCTS.NWODBLUE.SHORT_NAME,
						labelFull: OPSWAT_PRODUCTS.NWODBLUE.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.NWODBLUE.KEY,
						name: '',
						link: '/inventory/netwall/optical-diode-blue',
						hidden: false,
					},
					{
						label: OPSWAT_PRODUCTS.NWODRED.SHORT_NAME,
						labelFull: OPSWAT_PRODUCTS.NWODRED.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.NWODRED.KEY,
						name: '',
						link: '/inventory/netwall/optical-diode-red',
						hidden: false,
					},
					// Transfer Guard
					{
						label: OPSWAT_PRODUCTS.NWTGBLUE.SHORT_NAME,
						labelFull: OPSWAT_PRODUCTS.NWTGBLUE.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.NWTGBLUE.KEY,
						name: '',
						link: '/inventory/netwall/transfer-guard-blue',
						hidden: false,
					},
					{
						label: OPSWAT_PRODUCTS.NWTGRED.SHORT_NAME,
						labelFull: OPSWAT_PRODUCTS.NWTGRED.FULL_NAME,
						permissionKey: OPSWAT_PRODUCTS.NWTGRED.KEY,
						name: '',
						link: '/inventory/netwall/transfer-guard-red',
						hidden: false,
					},
				],
			},
			{
				label: OPSWAT_PRODUCTS.MDSSC.FULL_NAME,
				permissionKey: OPSWAT_PRODUCTS.MDSSC.KEY,
				name: 'mdssc',
				link: '/inventory/supply-chain',
				hidden: false,
				showChildren: false,
				subItems: [],
			},
		],
	},
	{
		icon: 'users',
		label: 'User Management',
		name: 'userManagement',
		link: URL_CONSTANT.USER_MANAGEMENT.USER_MANAGEMENT,
		hidden: false,
		showChildren: true,
		subItems: [],
	},
	{
		icon: 'calendar',
		label: 'Events History',
		name: 'eventsHistory',
		link: URL_CONSTANT.LOG,
		hidden: false,
		showChildren: true,
		subItems: [],
	},
	{
		icon: 'policy',
		label: 'Licenses',
		name: 'licenses',
		link: '/licenses',
		hidden: false,
		showChildren: true,
		subItems: [],
	},
	{
		icon: 'settings',
		label: 'Settings',
		name: 'settings',
		hidden: false,
		showChildren: true,
		link: '/settings',
		subItems: [
			{
				label: 'Global',
				permissionKey: 'global',
				name: 'global',
				link: '/settings/global',
				hidden: false,
			},
			{
				label: 'Products',
				permissionKey: 'products',
				name: 'products',
				link: '/settings/products',
				hidden: false,
			},
			{
				label: 'Reports & Notifications',
				permissionKey: 'reports',
				name: 'reports',
				link: '/settings/reports',
				hidden: false,
			},
			{
				label: 'Integrations',
				permissionKey: 'integrations',
				name: 'integrations',
				link: '/settings/integrations',
				hidden: false,
			},
			{
				label: 'Tools',
				permissionKey: 'tools',
				name: 'tools',
				link: '/settings/tools',
				hidden: false,
			},
		],
	},
	// {
	// 	icon: 'policy',
	// 	label: 'Licenses',
	// 	name: 'licenses',
	// 	link: '/licenses',
	// 	hidden: false,
	// },
	// {
	// 	icon: 'life-saver',
	// 	label: 'Support',
	// 	name: 'supports',
	// 	link: URL_CONSTANT.SUPPORT,
	// 	hidden: false,
	// },
];
