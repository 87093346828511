import { Injectable } from '@angular/core';
import { DEFAULT_LIMIT_ITEMS, DEFAULT_PAGE, PAGE_SIZE_OPTIONS } from '@app/shared/constants';
import { handleConvertFromTime, handleConvertToTime, isEqualWithSameOrder } from '@app/shared/utils';
import { SelectionService, UrlParamsService } from '@opswat/services';
import { DIRECTION } from '../../constants';
import { IGeneralTableCriteria } from '../../models';

@Injectable()
export class TableService {
	displayedColumns: string[] = [];
	defaultPageSize = PAGE_SIZE_OPTIONS;
	filters: any = '';
	defaultSort = {
		active: '',
		direction: DIRECTION.DESC,
	};
	emptyResultMsg = '';
	isLoadingTable = true;
	filterInfo = '';
	searchInfo = '';

	constructor(public urlParamsService: UrlParamsService, public selectionService: SelectionService) {}

	// merge elements have same key to an array
	buildFilter(rawFilters: any, productType = ''): any {
		const results: any = {};
		try {
			rawFilters.forEach((filter: any) => {
				const keys = Object.keys(results);
				const found = keys.indexOf(filter.key) > -1;
				if (!found) {
					results[filter.key] = [filter.value];
				} else {
					results[filter.key].push(filter.value);
				}
			});
		} catch {
			console.log('Can not build Filter');
		}
		if (productType) {
			results['productType'] = [productType];
		}
		return results;
	}

	buildSearchCriteria(productType = ''): IGeneralTableCriteria {
		const searchCriteria: IGeneralTableCriteria = {
			searchText: '',
			filters: [],
			orders: [{ field: '', order: DIRECTION.DESC }],
			page: DEFAULT_PAGE,
			limit: DEFAULT_LIMIT_ITEMS,
		};
		const { search, sort, page, limit } = this.urlParamsService.params.current;
		searchCriteria.searchText = search;
		searchCriteria.page = page;
		searchCriteria.limit = limit;
		searchCriteria.filters = this.buildFilter([...this.urlParamsService.getFilters('current', '')], productType);
		searchCriteria.orders[0].field = sort.active;
		searchCriteria.orders[0].order = sort.direction;

		return searchCriteria;
	}

	onResetTable(defaultSort = { active: '', direction: DIRECTION.DESC }) {
		this.setDefaultParam(defaultSort.active);
		this.urlParamsService.onQueryParamsNavigate();
	}

	buildFilterText(urlParamsService: any, filterList: any, ignoreFilter = ['age', 'timestampFrom', 'timestampTo']): string {
		let filterText = '';
		const currentFilters = urlParamsService.getFilters('current', ignoreFilter);

		const mergedObject = currentFilters.reduce((previousValue: any, currentValue: any) => {
			const found = previousValue.find((element: any) => element.key === currentValue.key); // the element in data property
			if (!found) {
				previousValue.push({ key: currentValue.key, values: [currentValue.value] }); // not found, so need to add data property
			} else {
				found.values.push(currentValue.value); // if found, that means data property exists, so just push new element to found.data.
			}
			return previousValue;
		}, []);

		mergedObject.forEach((filter: any) => {
			filterList.forEach((parentFilter: any) => {
				if (parentFilter.id === filter.key) {
					const filterValuesAsText = filter.values
						.reduce((previousValue: any, currentValue: any) => {
							parentFilter.children.forEach((childFilter: any) => {
								if (childFilter.children) {
									// check sub filter (children level 2)
									const subFilterArr = childFilter.children.filter((subFilter: any) =>
										this.isSelectedFilterItem(currentValue, subFilter.id)
									);

									subFilterArr.length && previousValue.push(...subFilterArr);
								} else if (this.isSelectedFilterItem(currentValue, childFilter.id)) {
									previousValue.push(childFilter);
								}
							});

							return previousValue;
						}, [])
						.map((item: any) => item.name)
						.join(', ');
					if (filterText.indexOf(parentFilter.name) && filterValuesAsText) {
						filterText += `${parentFilter.name} = ${filterValuesAsText}; `;
					}
				}
			});
		});
		return filterText;
	}

	isSelectedFilterItem(selectedValue: string, comparedValue: string) {
		return selectedValue === comparedValue;
	}

	isFiltering(urlParamsService: any): boolean {
		const currentFilters = urlParamsService.getFilters('current');
		return currentFilters.length > 0;
	}

	setDefaultSort(activeValue: string) {
		this.defaultSort.active = activeValue;
	}

	setDefaultParam(activeValue = '') {
		if (activeValue) {
			this.setDefaultSort(activeValue);
		}
		if (this.defaultSort) {
			this.urlParamsService.setDefaults({
				page: DEFAULT_PAGE,
				limit: DEFAULT_LIMIT_ITEMS,
				filters: {},
				search: '',
				sort: this.defaultSort,
			});
		}
	}

	setDisplayedColumns(columns: string[]) {
		this.displayedColumns = columns;
	}

	setDefaultFilters(filters: any) {
		this.filters = filters;
	}

	setEmptyResultMessage(message: string) {
		this.emptyResultMsg = message;
	}

	isParamsChanged(): boolean {
		const params = this.urlParamsService.getParams();
		return isEqualWithSameOrder(params.current, params.default);
	}

	clearSelection() {
		this.selectionService.selection.clear();
	}

	toggleFilter(event: any, key: any, value: any) {
		if (key === 'age' || key === 'timestampFrom' || key === 'timestampTo') {
			this.urlParamsService.params.current.filters[key] = value;
			return;
		}
		let newValue = value;
		if (!this.urlParamsService.params.current.filters.hasOwnProperty(key)) {
			this.urlParamsService.params.current.filters[key] = value;
			return;
		}
		let parts = [];
		if (key !== 'age' && key !== 'timestampFrom' && key !== 'timestampTo') {
			parts = this.urlParamsService.params.current.filters[key].split(',');
		}
		const index = parts.indexOf(value);
		if (event.checked) {
			if (typeof this.urlParamsService.params.current.filters[key] === 'string') {
				if (index === -1) {
					parts.push(value);
				}
				newValue = parts.sort().join(',');
			}
		} else {
			if (index > -1) {
				parts.splice(index, 1);
			}
			newValue = parts.sort().join(',');
		}
		if (newValue.length) {
			this.urlParamsService.params.current.filters[key] = newValue;
			return;
		}
		delete this.urlParamsService.params.current.filters[key];
	}

	findCustomTimeValue(data: any[]): any | null {
		for (const item of data) {
			if (item.custom) {
				return item.custom;
			}

			if (item.children) {
				const result = this.findCustomTimeValue(item.children);
				if (result) {
					return result;
				}
			}
		}
		return null;
	}

	setDefaultFilterParams() {
		const payload = this.findCustomTimeValue(this.filters);

		if (payload === null) {
			return;
		}

		this.toggleFilter({ value: payload.parentValue }, payload.parentName, payload.parentValue);
		this.toggleFilter({ value: payload.value }, payload.fromName, handleConvertFromTime(payload.value));
		this.toggleFilter({ value: payload.value }, payload.toName, handleConvertToTime());
		this.urlParamsService.onUpdateFilters();
	}

	buildFilterAsText(queryParams: { [key: string]: any }) {
		const skipKeys = new Set(['search', 'age', 'timestampFrom', 'timestampTo', 'groupIds', 'instanceIds', 'sort', 'limit', 'page']);
		const resultArr: string[] = [];

		for (const key of Object.keys(queryParams)) {
			if (skipKeys.has(key)) {
				continue;
			};

			const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
			let decodedValue = decodeURIComponent(queryParams[key]);
			decodedValue = decodedValue.replace(/,/g, ', ');

			resultArr.push(`${capitalizedKey} = ${decodedValue}`);
		}

		return resultArr.join('; ');
	}
}
