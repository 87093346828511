import { animate, state, style, transition, trigger } from '@angular/animations';
import {
	ChangeDetectorRef,
	Component,
	DestroyRef,
	ElementRef,
	inject,
	Inject,
	LOCALE_ID,
	OnDestroy,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
	DIALOG_HEIGHT,
	DIALOG_WIDTH,
	OPSWAT_PRODUCTS,
	PERMISSION,
	PERMISSION_KEY,
	SUPPORT_LEVEL,
	URL_CONSTANT,
} from '@app/shared/constants';
import { CmUtilService, RbacService, ToastService } from '@app/shared/services';
import { PublisherService } from '@app/shared/services/publisher/publisher.service';
import { environment } from '@env/environment';
import { ChartComponent } from 'ng-apexcharts';
import { fromEvent } from 'rxjs';
import { SettingsService } from '../../settings/services';
import {
	FILTER_ENUM,
	PRODUCT_CONNECTIVITY,
	PRODUCT_HEALTH,
	PRODUCT_LICENSES,
	PRODUCT_VERSIONS,
	donutChartOptions,
	stackedBarOptions,
} from '../constants';
import { AxisChartOptions, NonAxisChartOptions } from '../models';
import { IInventoryProduct, IProductCatalog, IProductStatistic } from '../models/inventory.model';
import { InventoryOverviewService } from '../services/inventory/inventory-overview.service';
import { HowToEnrollDialogComponent } from './how-to-enroll-dialog/how-to-enroll-dialog.component';
import { dateHelper, showHttpErrorMsg } from '@app/shared/utils';
import { switchMap, filter, take } from 'rxjs/operators';
import { NotificationService } from '@app/shared/services/notification/notification.service';
import { FILTER_OPTIONS, UNIT_FILTER } from '../metadefender-kiosk/overview/dashboard/constants';
import { ChosenDate } from 'ngx-daterangepicker-material/daterangepicker.component';
import { IFilterOption } from '../metadefender-kiosk/overview/dashboard/model';
import { IncidentAlertPannelComponent } from './incident-alert-pannel/incident-alert-pannel.component';
import { IncidentDetailDialogComponent } from './incident-detail-dialog/incident-detail-dialog.component';
import { UtilsService } from '@opswat/services';
import { IIncidentAllProducts, IIncidentByProduct, IIncidentDetailItem } from '../models/incident.model';
import {
	INCIDENT_DISPLAY_SEVERITY,
	INCIDENT_FROM_KEY,
	IncidentSeverity,
	IncidentTypes,
	SECURITY_INCIDENT,
	SEVERITY_LEVEL_MAPING,
	SEVERITY_SETTING,
} from '../constants/incident.constant';
import { INotification } from '@app/shared/models';
import { SecurityAlertSettingComponent } from './security-alert-setting/security-alert-setting.component';

import dayjs from 'dayjs/esm';
import timezone from 'dayjs/esm/plugin/timezone';
import utc from 'dayjs/esm/plugin/utc';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
	selector: 'app-inventory-overview',
	templateUrl: './inventory-overview.component.html',
	styleUrls: ['./inventory-overview.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed,void', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class InventoryOverviewComponent implements OnInit, OnDestroy {
	@ViewChildren('semichart') chartRefs!: QueryList<ChartComponent>;
	@ViewChild('dateRangePicker', { read: ElementRef }) pickerDirective!: ElementRef;
	OPSWAT_PRODUCT = OPSWAT_PRODUCTS;
	URL_CONSTANT = URL_CONSTANT;
	OTHER_PROUDCT = 'OTHER';
	DEFAULT_IMG = 'assets/images/default.png';
	PRODUCT_ASSET = 'assets/images/product/';
	products: IProductCatalog = {
		total: 0,
		managed: [],
		unmanaged: [],
		learnMore: [],
	};
	localID: string;
	isLoading = false;
	isTabLoading = false;
	selectedTabIndex = 0;
	tabList = {
		0: 'overview',
		1: 'security',
	};

	lastUpdate: any;
	showCalendar = false;
	filterOptions = FILTER_OPTIONS;
	inlineDate!: ChosenDate;
	startDate = dayjs().tz(dateHelper.getTimezone());
	endDate = dayjs().tz(dateHelper.getTimezone());
	maxDate = dayjs().tz(dateHelper.getTimezone()).endOf('day');
	DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';
	calendarMatIconEvent!: Event;
	selectedElement: IFilterOption = FILTER_OPTIONS[0];
	searchCriteria: any;
	@ViewChildren('filterOptionElements') filterOptionElements!: QueryList<ElementRef>;

	columnsToDisplay = ['productName', 'threat', 'sensitiveData', 'suspicious', 'potentiallyVulnerable'];

	aboutOneCentral: any;
	OCMURL = '';
	hasIncident = false;
	environment = environment;
	subscriptions: any = {
		serverUrl: null,
		incident: null,
	};

	urlDocument = 'https://docs.opswat.com/';
	incidentAllProducts: IIncidentAllProducts[] = [];

	incidentByProducts: IIncidentByProduct[] = [];
	incidents: any = [];
	topIncidents: any = [];
	numOfIncidentShown = 3;
	PERMISSION = PERMISSION;
	permission = PERMISSION.NONE;
	destroyRef = inject(DestroyRef);

	constructor(
		@Inject(LOCALE_ID) localID: string,
		private readonly inventoryOverviewService: InventoryOverviewService,
		private readonly toastService: ToastService,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly dialog: MatDialog,
		private readonly settingsService: SettingsService,
		private readonly cmUtilService: CmUtilService,
		private readonly utilService: UtilsService,
		private readonly publisherService: PublisherService,
		public notificationService: NotificationService,
		private readonly changeDetector: ChangeDetectorRef,
		private readonly rbacService: RbacService
	) {
		this.localID = localID;
	}

	ngOnInit(): void {
		this.getInventoryStatistic();
		this.getIncidentStatistic();
		this.getServerUrl();
		this.getAboutOneCentral();
		this.notificationService
			.getNotifications(['security_incident'])
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((data) => {
				this.hasIncident = data.length ? true : false;
				this.incidents = this.prepareIncidentAlert(data);
				this.topIncidents = this.getTopIncidents(this.incidents, this.numOfIncidentShown);
				this.publisherService.publish('securityIncidents', this.incidents);
			});
		this.subscriptions.incident = this.publisherService
			.follow('updateSecurityIncident')
			.pipe(
				filter((fetchNew) => fetchNew === true),
				switchMap(() => this.notificationService.getNotifications(['security_incident'])),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe((data) => {
				this.hasIncident = data.length ? true : false;
				this.incidents = this.prepareIncidentAlert(data);
				this.publisherService.publish('securityIncidents', this.incidents);
			});

		this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
			if (params && params?.hasOwnProperty('tab') && params.tab === 'security') {
				this.selectedTabIndex = 1;
			} else {
				this.selectedTabIndex = 0;
			}
		});

		this.publisherService
			.follow('hasSecurityIncident')
			.pipe(take(1))
			.subscribe((hasIncident) => {
				if (hasIncident) {
					this.selectedTabIndex = 1;
				}
			});

		this.permission = this.rbacService.getPermission(PERMISSION_KEY.inventory);
	}

	ngOnDestroy() {
		this.utilService.unsubscribe(this.subscriptions);
	}

	updateTabchange() {
		this.isTabLoading = true;
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { tab: this.tabList[this.selectedTabIndex] },
			queryParamsHandling: 'merge',
		});

		setTimeout(() => (this.isTabLoading = false), 0);
	}

	selectElement(element: IFilterOption) {
		if (this.selectedElement.value === element.value && element.name !== FILTER_ENUM.CUSTOM) {
			return;
		}

		if (element.name === FILTER_ENUM.CUSTOM) {
			this.showCalendar = true;

			this.changeDetector.detectChanges();

			const subscription = fromEvent(document, 'click').subscribe((event: Event) => {
				const customOptionElement = this.filterOptionElements.find(
					(elementRef: ElementRef) => elementRef.nativeElement.id === FILTER_ENUM.CUSTOM
				);

				if (
					event.target !== this.pickerDirective?.nativeElement &&
					event.target !== customOptionElement?.nativeElement &&
					event.target !== this.calendarMatIconEvent?.target &&
					this.showCalendar
				) {
					this.showCalendar = false;
					subscription.unsubscribe();
				}
			});
			return;
		}

		this.selectedElement = element;
		this.getIncidentStatistic();
	}

	datesUpdated(e: any) {
		this.selectedElement = {
			name: FILTER_ENUM.CUSTOM,
			value: {
				amount: 0,
				unit: UNIT_FILTER.DAY,
			},
		};
		this.showCalendar = false;
		this.startDate = e.startDate;
		this.endDate = e.endDate;
		let amount = this.endDate.diff(this.startDate, 'day') + 1;
		amount = amount <= 0 ? 1 : amount;

		this.selectedElement.value.amount = amount;
		this.getIncidentStatistic();
	}

	cancelClicked(event: any) {
		this.showCalendar = false;
	}

	setCalendarMatIconEvent(event: Event) {
		// need to get this event because clicking on calendar mat-icon returns an svg object instead of an elementRef
		this.calendarMatIconEvent = event;
	}

	getInventoryStatistic() {
		this.isLoading = true;
		this.inventoryOverviewService
			.getOverviewInstance()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(
				(data) => {
					this.markLastUpdate();

					const managed = data
						.filter((item) => {
							return item.totalInstances > 0;
						})
						.sort(this.sortFunction);
					const unmanaged = data
						.filter((item) => {
							return item.totalInstances <= 0 && item.activeLicense && item.productType !== this.OTHER_PROUDCT;
						})
						.sort(this.sortFunction);
					const learnMore = data
						.filter((item) => {
							return (
								(item.totalInstances <= 0 && !item.activeLicense) ||
								(item.totalInstances <= 0 && item.activeLicense && item.productType === this.OTHER_PROUDCT)
							);
						})
						.sort(this.sortFunction);
					this.products.managed = this.convertProductData(managed);
					this.products.unmanaged = this.convertProductData(unmanaged);
					this.products.learnMore = this.convertProductData(learnMore);
					this.products.total = data.length;
					this.isLoading = false;
				},
				(errorReponse) => {
					this.isLoading = false;
				}
			);
	}

	sortFunction(item1: IProductStatistic, item2: IProductStatistic) {
		return item1.productName.localeCompare(item2.productName);
	}

	markLastUpdate() {
		this.lastUpdate = dayjs().valueOf();
	}

	goToProduct(type: string) {
		if (type === OPSWAT_PRODUCTS.KIOSK.TYPE || type === OPSWAT_PRODUCTS.MK5.TYPE) {
			this.router.navigate([`${URL_CONSTANT.INVENTORY.INVENTORY}${URL_CONSTANT.INVENTORY.KIOSK_OVERVIEW}`]);
		} else {
			this.router.navigate([`${URL_CONSTANT.INVENTORY.INVENTORY}${URL_CONSTANT.INVENTORY[type].INSTANCE_LIST}`]);
		}
	}

	showHowToEnroll(type: string) {
		this.dialog.open(HowToEnrollDialogComponent, {
			height: DIALOG_HEIGHT.MEDIUM,
			width: DIALOG_WIDTH.MEDIUM,
			autoFocus: false,
			disableClose: false,
			data: {
				type: type,
			},
		});
	}

	learnMoreInMyOpswat(slug: string) {
		const link = `${environment.myOpswat}/home/${slug}`;
		location.href = link;
	}

	convertProductData(data: IProductStatistic[]): IInventoryProduct[] {
		const rows: any = [];
		data.forEach((item: IProductStatistic) => {
			try {
				const key = OPSWAT_PRODUCTS[item.productType]?.KEY ? OPSWAT_PRODUCTS[item.productType].KEY : '';
				const defaultImg =
					item.productType === this.OTHER_PROUDCT ? this.DEFAULT_IMG : `${this.PRODUCT_ASSET}${item.productType}.png`;
				const image = item.imageUrl ? item.imageUrl : defaultImg;
				// Workaround for mdemail name
				const name = item.productType === OPSWAT_PRODUCTS.MDEMAIL.TYPE ? OPSWAT_PRODUCTS.MDEMAIL.FULL_NAME : item.productName;

				const product: IInventoryProduct = {
					type: item.productType,
					key: key,
					name: name,
					total: item.totalInstances,
					license: item.activeLicense,
					description: item.description,
					image: image,
					slug: item.slug,
				};
				if (item.totalInstances > 0) {
					//workaround for otfuse
					if (item.productType === OPSWAT_PRODUCTS.OTFUSE.TYPE) {
						item.licenses.expire90PlusDays =
							item.licenses.expire90PlusDays +
							item.licenses.expire30Days +
							item.licenses.expire90Days +
							item.licenses.expired;
						item.licenses.expire30Days = 0;
						item.licenses.expire90Days = 0;
						item.licenses.expired = 0;
					}
					product.active = item.active;
					product.connectivity = this.prepareConnectivityData(item);
					product.versions = this.prepareVersionsData(item);
					product.licenses = this.prepareLicensesData(item);
					product.health = this.prepareHealthData(item);
				}
				rows.push(product);
			} catch (error) {
				console.log(error);
			}
		});
		return rows;
	}
	prepareConnectivityData(item: IProductStatistic): NonAxisChartOptions {
		const connectivityOptions = JSON.parse(JSON.stringify(donutChartOptions));
		connectivityOptions.series = [item.active, item.totalInstances - item.active];
		connectivityOptions.labels = [PRODUCT_CONNECTIVITY.active.label, PRODUCT_CONNECTIVITY.inactive.label];
		connectivityOptions.colors = [PRODUCT_CONNECTIVITY.active.color, PRODUCT_CONNECTIVITY.inactive.color];
		connectivityOptions.plotOptions.pie.donut.labels.total.formatter = (w: any) => {
			return `${item.active}/${item.totalInstances}`;
		};
		return connectivityOptions;
	}

	prepareVersionsData(item: IProductStatistic): AxisChartOptions {
		const versionsOptions = JSON.parse(JSON.stringify(stackedBarOptions));
		const series: any[] = [];
		Object.keys(PRODUCT_VERSIONS).forEach((key) => {
			const keyTotal = item.version
				.filter((it) => {
					return it.status === key;
				})
				.reduce((accumulator, it) => {
					return accumulator + it.total;
				}, 0);
			series.push({ name: PRODUCT_VERSIONS[key].label, data: [keyTotal] });
		});
		versionsOptions.series = series;
		versionsOptions.colors = [
			PRODUCT_VERSIONS['LATEST'].color,
			PRODUCT_VERSIONS['UP TO DATE'].color,
			PRODUCT_VERSIONS['SLIGHT BEHIND'].color,
			PRODUCT_VERSIONS['BEHIND'].color,
			PRODUCT_VERSIONS['MAJOR BEHIND'].color,
			PRODUCT_VERSIONS['Others'].color,
		];
		versionsOptions.tooltip.y.formatter = (value: any, { fseries, seriesIndex, dataPointIndex, w }: any) => {
			return parseInt(value).toString();
		};
		return versionsOptions;
	}
	prepareLicensesData(item: IProductStatistic): AxisChartOptions {
		const licensesOptions = JSON.parse(JSON.stringify(stackedBarOptions));
		licensesOptions.series = [
			{ name: PRODUCT_LICENSES.expire90PlusDays.label, data: [item.licenses.expire90PlusDays] },
			{ name: PRODUCT_LICENSES.expire90Days.label, data: [item.licenses.expire90Days] },
			{ name: PRODUCT_LICENSES.expire30Days.label, data: [item.licenses.expire30Days] },
			{ name: PRODUCT_LICENSES.expired.label, data: [item.licenses.expired] },
			{ name: PRODUCT_LICENSES.noLicense.label, data: [item.licenses.noLicense] },
		];
		licensesOptions.colors = [
			PRODUCT_LICENSES.expire90PlusDays.color,
			PRODUCT_LICENSES.expire90Days.color,
			PRODUCT_LICENSES.expire30Days.color,
			PRODUCT_LICENSES.expired.color,
			PRODUCT_LICENSES.noLicense.color,
		];
		licensesOptions.tooltip.y.formatter = (value: any, { fseries, seriesIndex, dataPointIndex, w }: any) => {
			return parseInt(value).toString();
		};
		return licensesOptions;
	}
	prepareHealthData(item: IProductStatistic): AxisChartOptions {
		const healthOptions = JSON.parse(JSON.stringify(stackedBarOptions));
		healthOptions.series = [
			{ name: PRODUCT_HEALTH.operational.label, data: [item.health.operational] },
			{ name: PRODUCT_HEALTH.blockerIssues.label, data: [item.health.blockerIssues] },
			{ name: PRODUCT_HEALTH.criticalIssues.label, data: [item.health.criticalIssues] },
			{ name: PRODUCT_HEALTH.significantIssues.label, data: [item.health.significantIssues] },
			{ name: PRODUCT_HEALTH.minorIssues.label, data: [item.health.minorIssues] },
			{ name: PRODUCT_HEALTH.unknown.label, data: [item.health.unknown] },
		];
		healthOptions.colors = [
			PRODUCT_HEALTH.operational.color,
			PRODUCT_HEALTH.blockerIssues.color,
			PRODUCT_HEALTH.criticalIssues.color,
			PRODUCT_HEALTH.significantIssues.color,
			PRODUCT_HEALTH.minorIssues.color,
			PRODUCT_HEALTH.unknown.color,
		];
		healthOptions.tooltip.y.formatter = (value: any, { fseries, seriesIndex, dataPointIndex, w }: any) => {
			return parseInt(value).toString();
		};
		return healthOptions;
	}

	getAboutOneCentral() {
		this.settingsService.getAboutOneCentral().subscribe(
			(aboutOnceCentral: any) => {
				this.aboutOneCentral = aboutOnceCentral;
			},
			({ error, status }) => {
				if (error) {
					showHttpErrorMsg(this.toastService, status, 'get about oneCentral', error.error);
				}
			}
		);
	}

	copyTempPassword(text: string) {
		this.cmUtilService.copyToClipBoard(text);
	}

	getServerUrl() {
		if (environment.onCloud) {
			this.OCMURL = environment.productUrl;
		} else {
			this.subscriptions.serverUrl = this.publisherService
				.follow('currentUser')
				.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe((user: any) => {
					if (user && user?.serverUrl) {
						this.OCMURL = user?.serverUrl;
					}
				});
		}
	}

	/*
	 * Security and incident
	 */
	openIncidentAlertPannel() {
		this.dialog.open(IncidentAlertPannelComponent, { disableClose: true, width: DIALOG_WIDTH.EXTRA_LARGE, data: this.incidents });
	}

	openIncidentDetailPannel(incidentKey: string, productType: string) {
		const issueType = INCIDENT_FROM_KEY[incidentKey];
		this.dialog.open(IncidentDetailDialogComponent, {
			disableClose: true,
			width: DIALOG_WIDTH.EXTRA_LARGE,
			data: {
				productType: productType,
				issue: issueType,
				filter: this.searchCriteria,
			},
		});
	}

	openCustomIncidentDialog() {
		this.dialog.open(SecurityAlertSettingComponent, { disableClose: true, width: DIALOG_WIDTH.LARGE });
	}
	onCloseNotification(id: string) {
		const index = this.incidents.findIndex((item: any) => item?.id === id);
		if (index !== -1) {
			this.incidents.splice(index, 1);
		}
		this.notificationService.checkMessage([id]).subscribe(() => {});
	}

	getIncidentSeverity(type: IncidentTypes): IncidentSeverity {
		const setting = SEVERITY_SETTING.find((item) => item.issue === type);
		if (setting) {
			return setting.severity;
		} else {
			return IncidentSeverity.NONE;
		}
	}

	getIncidentStatistic() {
		this.isTabLoading = true;
		this.searchCriteria = this.buildSearchCriteria();
		this.inventoryOverviewService
			.getOverviewIncident(this.searchCriteria)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(
				(data: IIncidentByProduct[]) => {
					this.markLastUpdate();
					this.incidentAllProducts = this.prepareIncidentOverview(data);
					this.incidentByProducts = data;
					this.isTabLoading = false;
				},
				({ error, status }) => {
					this.isTabLoading = false;
					if (error) {
						showHttpErrorMsg(this.toastService, status, 'Get Incident Overview', error.error);
					}
				}
			);
	}

	buildSearchCriteria() {
		const timeRangeFitler = this.getTimeRangeFilter(this.selectedElement, this.endDate);
		const reportSupportedProducts = Object.values(OPSWAT_PRODUCTS).filter((product) => product.SUPPORT_LEVEL >= SUPPORT_LEVEL.LEVEL_0);
		const prouductQuery: any[] = [];
		reportSupportedProducts.forEach((product) =>
			prouductQuery.push({
				productType: product.TYPE,
				groupIds: [],
			})
		);
		return {
			endTime: timeRangeFitler.endTime,
			groupsByProductType: prouductQuery,
			utcOffset: timeRangeFitler.utcOffset,
			amount: timeRangeFitler.amount,
			unit: timeRangeFitler.unit,
		};
	}

	getTimeRangeFilter(selectedElement: IFilterOption, endDate = dayjs()) {
		const utcOffset = dayjs().tz(dateHelper.getTimezone()).utcOffset();
		if (selectedElement.name !== FILTER_ENUM.CUSTOM) {
			const currentTime = dayjs().valueOf();
			return {
				unit: selectedElement.value.unit,
				endTime: currentTime,
				utcOffset: dateHelper.getFormatedUtcOffset(utcOffset),
				amount: selectedElement.value.amount,
			};
		} else {
			const offset = dayjs().tz(dateHelper.getTimezone()).utcOffset();
			const endTime = endDate.utc().valueOf() - offset * 60 * 1000;
			return {
				unit: UNIT_FILTER.DAY,
				endTime: endTime,
				utcOffset: dateHelper.getFormatedUtcOffset(utcOffset),
				amount: selectedElement.value.amount,
			};
		}
	}

	prepareIncidentOverview(data: IIncidentByProduct[]): any {
		const threat = { name: SECURITY_INCIDENT[IncidentTypes.THREAT], type: IncidentTypes.THREAT, value: 0 };
		const sensitive = { name: SECURITY_INCIDENT[IncidentTypes.SENSITIVE_DATA], type: IncidentTypes.SENSITIVE_DATA, value: 0 };
		const suspicious = { name: SECURITY_INCIDENT[IncidentTypes.SUSPICIOUS], type: IncidentTypes.SUSPICIOUS, value: 0 };
		const vulnerable = {
			name: SECURITY_INCIDENT[IncidentTypes.POTENTIALLY_VULNERABLE],
			type: IncidentTypes.POTENTIALLY_VULNERABLE,
			value: 0,
		};
		const others = { name: SECURITY_INCIDENT[IncidentTypes.OTHERS], type: IncidentTypes.OTHERS, value: 0 };
		for (const item of data) {
			const overviewProduct = item;
			overviewProduct.productName = OPSWAT_PRODUCTS[overviewProduct.productType].FULL_NAME;
			threat.value += overviewProduct.threat;
			sensitive.value += overviewProduct.sensitiveData;
			suspicious.value += overviewProduct.suspicious;
			vulnerable.value += overviewProduct.potentiallyVulnerable;
			others.value += overviewProduct?.others;
		}
		return [threat, sensitive, suspicious, vulnerable];
	}

	getTopIncidents(array: any, count: number) {
		if (!Array.isArray(array)) {
			throw new Error('Invalid input: The first argument must be an array.');
		}
		if (count <= 0) {
			return [];
		}
		return array.slice(0, count);
	}

	prepareIncidentAlert(data: INotification[]) {
		const incidents: any = [];
		for (const item of data) {
			const severity = (item?.severity || IncidentSeverity.NONE) as IncidentSeverity;
			const incident: IIncidentDetailItem = {
				id: item.id,
				productName: OPSWAT_PRODUCTS[item?.info?.productType || '']?.FULL_NAME,
				productType: item?.info?.productType || '',
				issue: SECURITY_INCIDENT[IncidentTypes[item?.info?.issue || 'OTHERS']],
				instanceName: item?.info?.instanceName || '',
				groupName: item?.info?.groupName || '',
				filePath: item?.info?.filePath || '',
				malware: item?.info?.malware || '',
				user: item?.info?.user || '',
				mediaType: item?.info?.mediaType || '',
				startTime: parseInt(item?.createdAt) || 0,
				severity: severity,
				displaySeverity: INCIDENT_DISPLAY_SEVERITY[severity],
				viewFull: false,
			};
			incidents.push(incident);
		}
		incidents.sort(this.customSortForIncident);
		return incidents;
	}

	customSortForIncident(i1: IIncidentDetailItem, i2: IIncidentDetailItem) {
		if (i1.severity !== i2.severity) {
			return (
				SEVERITY_LEVEL_MAPING[i2?.severity || IncidentSeverity.NONE] - SEVERITY_LEVEL_MAPING[i1?.severity || IncidentSeverity.NONE]
			);
		}
		// If values are equal, sort by timeCreated
		return i2.startTime - i1.startTime;
	}

	viewMore(incident: any) {
		incident.viewFull = !incident.viewFull;
	}
}
