import dayjs from 'dayjs';
import { EVENT_TIME } from '../constants';
import { dateHelper } from './date-helper';

export const filterInfo = (filterOriginal: any, params: any) => {
	let paramsKey = Object.keys(params);
	if (!paramsKey.length) {
		return '';
	}
	const filterKey: any = [];
	filterOriginal.forEach((element: any) => {
		filterKey.push(element.id);
	});

	paramsKey = paramsKey.filter((item) => filterKey.includes(item));
	if (!paramsKey.length) {
		return '';
	}
	let result = 'Filter: ';
	for (let i = 0; i < paramsKey.length; i++) {
		const filter = filterOriginal.find((filterItem: any) => filterItem.id === paramsKey[i]);
		const paramValues = params[paramsKey[i]].split(',');
		result += filter.name + ' = ';
		for (let j = 0; j < paramValues.length; j++) {
			const childrenName = filter.children.find((child: any) => child.id === paramValues[j])?.name;
			if (j === paramValues.length - 1) {
				if (i === paramsKey.length - 1) {
					result += childrenName;
				} else {
					result += childrenName + '; ';
				}
			} else {
				result += childrenName + ' + ';
			}
		}
	}
	return result;
};

export const handleConvertCustomTime = (data: any) => {
	return dayjs(data).valueOf();
};

export const handleConvertFromTime = (data: any) => {
	if (data) {
		return dayjs().subtract(Number.parseInt(data.toString()), 'days').valueOf();
	}

	return dayjs().startOf('day').subtract(1, 'days').valueOf();
};

export const handleConvertToTime = () => {
	return dayjs().valueOf();
};

export const handleDisplayTime = (timestamp: any, format?: string) => {
	if (timestamp) {
		return dayjs(Number.parseInt(timestamp.toString()))
			.tz(dateHelper.getTimezone())
			.format(format || EVENT_TIME.TIME_FORMAT);
	}

	return null;
};

export const handleGetDaysFromTimestamp = (selectedTime: number, timestampFrom: string, timestampTo: string) => {
	if (selectedTime === 0) {
		const diffTime = parseInt(timestampTo) - parseInt(timestampFrom);
		const diffDays = Math.round(diffTime / (1000 * 3600 * 24));
		const diffOneDay = 1;

		if (diffDays < diffOneDay) {
			return 24;
		} else {
			return diffDays;
		}
	} else if (selectedTime === 1) {
		return 24;
	} else {
		return selectedTime;
	}
};

export const handleGetUnitFromTimestamp = (selectedTime: number, timestampFrom: string, timestampTo: string) => {
	if (selectedTime === 0) {
		const diffTime = parseInt(timestampTo) - parseInt(timestampFrom);
		const diffDays = Math.round(diffTime / (1000 * 3600 * 24));
		const diffOneDay = 1;

		if (diffDays < diffOneDay) {
			return 'hour';
		} else {
			return 'day';
		}
	} else if (selectedTime === 1) {
		return 'hour';
	} else {
		return 'day';
	}
};

export const handleGetUtcOffset = () => {
	return dateHelper.getFormatedUtcOffset(dayjs().tz(dateHelper.getTimezone()).utcOffset());
};

export const parseCategoryFilter = (id: string, name: string, items: any) => {
	items = items?.sort();
	return {
		type: 'checkbox',
		id: id,
		name: name,
		children: Array.from(new Set(items || [])).map((item) => ({
			id: item,
			name: item,
		})),
		showSearchIcon: id === 'result' ? true : false,
		searchTerm: '',
	};
};

export const getIdsValue = (idsValue: any) => {
	if (idsValue?.length > 0) {
		return idsValue?.map((item: any) => item.value);
	}

	return [];
};

export const handleGetInstanceName = (instanceList: any, instanceIdList: any) => {
	return instanceList.filter((item: any) => instanceIdList.includes(item.id)).map((item: any) => item.name);
};

export const handleRenderSearchFilterText = (age: any, fromTime: any, toTime: any) => {
	if (age >= 0 && fromTime && toTime) {
		const fromTimeConvert = dayjs(parseInt(fromTime)).format(EVENT_TIME.TIME_DISPLAY_FORMAT);
		const toTimeConvert = dayjs(parseInt(toTime)).format(EVENT_TIME.TIME_DISPLAY_FORMAT);

		const value = {
			0: `From ${fromTimeConvert} to ${toTimeConvert}`,
			1: 'Last 24 hours',
			7: 'Last 7 days',
			30: 'Last 30 days',
			365: 'Last 12 months',
		} as any;

		return value?.[age];
	}

	return '';
};
